import React from "react";

const Banner1 = ({ data: item }) => {
  return (
    <>
      <div className="container-2">
        <h4 className="h-heading pt-4">{item.heading}</h4>
        <h2 className="h-sub-heading font-weight-bold"> {item.subHeading}</h2>
      </div>
    </>
  );
};

export default Banner1;
