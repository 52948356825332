import Image from "next/image";
import React from "react";
import Block1 from "~/public/images/blocks/block1.png";
import Block2 from "~/public/images/blocks/block2.png";
import Block3 from "~/public/images/blocks/block3.png";
import Block4 from "~/public/images/blocks/block4.png";
import Block5 from "~/public/images/blocks/block5.png";
import Block6 from "~/public/images/blocks/block6.png";

const BrandProps = () => {
  return (
    <>
      <div className="gp-container advantages w-container-1">
        <div className="advantage advantage-1">
          <Image src={Block1} height={60} width={60} />
          <h5 className="mt-2 mb-2"> Nature-Inspired Beauty</h5>
          <div className="text-block">
            Holistic beauty is what we're all about.
          </div>
        </div>
        <div className="advantage">
          <Image src={Block2} height={60} width={60} />
          <h5 className="mb-2 mt-2">Himalayan Born</h5>
          <div className="text-block">
            We are deeply rooted to the Himalayas.
          </div>
        </div>
        <div className="advantage">
          <Image src={Block3} height={60} width={60} />
          <h5 className="mb-2 mt-2">Cruelty Free</h5>
          <div className="text-block">
            100% Vegan. Natural bioactives are our bread and butter.
          </div>
        </div>
      </div>
      <div className="gp-container advantages w-container-1">
        <div className="advantage advantage-1">
          <Image src={Block4} height={60} width={60} />
          <h5 className="mb-2 mt-2">Gluten-Free</h5>
          <div className="text-block">
            Made with alternative ingredients that provide the same cleansing
            and conditioning benefits without containing gluten.
          </div>
        </div>
        <div className="advantage">
          <Image src={Block5} height={60} width={60} />
          <h5 className="mb-2 mt-2">Paraben-free & Sulfate-free</h5>
          <div className="text-block">
            Our products are FREE from harsh detergent chemicals commonly used
            in many personal care and cleaning products.
          </div>
        </div>
        <div className="advantage">
          <Image src={Block6} height={60} width={60} />
          <h5 className="mb-2 mt-2">Amber Packaging</h5>
          <div className="text-block">
            Amber color of our bottle protects the purity of our ingredients
            preserving effectiveness and shelf-life.
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandProps;
