import Image from "next/image";
import React from "react";

import Ingredients1 from "~/public/images/home/ingredients1.jpeg";
import Ingredients2 from "~/public/images/home/ingredients2.jpeg";
import Ingredients3 from "~/public/images/home/ingredients3.jpeg";
import Ingredients4 from "~/public/images/home/ingredients4.jpeg";
import Ingredients5 from "~/public/images/home/ingredients5.jpeg";
import Ingredients6 from "~/public/images/home/ingredients6.jpeg";
import Ingredients7 from "~/public/images/home/ingredients7.jpeg";
import Ingredients8 from "~/public/images/home/acne-ing.jpeg";
import Ingredients9 from "~/public/images/home/Ingredients9.jpeg";
import TrustedBrand from "~/public/images/trusted-brand.jpeg";
import RmaryTrustedBrand from "~/public/images/home/rmary-trust.webp";
import TrustedBrandOnion from "~/public/images/onionKit/Trusted-Brand-Onion.png";

const IngredientSpecifications = ({ id }) => {
  let imageSrc;
  switch (id) {
    case "apple-cider-vinegar-shampoo-set":
      imageSrc = Ingredients3;
      break;
    case "apple-cider-vinegar-shampoo-set-2":
      imageSrc = Ingredients3;
      break;
    case "apple-cider-vinegar-face-wash":
      imageSrc = Ingredients9;
      break;
    case "apple-cider-vinegar-face-wash-set2":
      imageSrc = Ingredients9;
      break;
    case "vitamin-c-and-niacinamide-face-wash":
      imageSrc = TrustedBrand;
      break;
    case "rosemary-oil-shampoo-and-hair-conditioner-combo":
      imageSrc = RmaryTrustedBrand;
      break;
    default:
      imageSrc = Ingredients8;
      break;
  }

  return (
    <>
      {id === "aloe-vera-face-wash" ? (
        <>
          <div className="container-2">
            <h4 className="h-heading pt-4">
              Clean Ingredients Means Healthier and Radiant Skin
            </h4>
            <h2 className="h-sub-heading">
              Why Over 1 Million People Have Switched to Wow Skin Science
              Products
            </h2>
          </div>

          <div className="w-container reasons-top">
            <div className="w-row">
              <div className="w-col w-col-6">
                <h4 className="heading text-center">Clean and Non-Toxic</h4>
                <Image
                  className="reason-img"
                  src={Ingredients5}
                  width={637}
                  height={510}
                />
                <p className="routine-p">
                  Wow Skin Science products are non-toxic, chemical-free, and
                  safe for both you and the environment. Using natural
                  ingredients such as plant-based oils and extracts, natural
                  fragrances, and biodegradable surfactants, our products are
                  effective and gentle for daily use.
                </p>
              </div>
              <div className="w-col w-col-6">
                <h4 className="heading text-center ">Nature-inspired</h4>
                <Image
                  className="reason-img"
                  src={Ingredients2}
                  width={637}
                  height={510}
                />
                <p className="routine-p">
                  Experience the power of natural ingredients with Wow Skin
                  Science's plant-based hair care products, sourced from the
                  Himalayans for maximum efficacy. Say goodbye to harsh
                  chemicals and hello to effective, safe hair care.
                </p>
              </div>
            </div>
            <div className="w-row">
              <div className="w-col w-col-6">
                <h4 className="heading text-center ">Trusted Brand</h4>
                <Image
                  className="reason-img"
                  src={Ingredients6}
                  width={637}
                  height={510}
                />
                <p className="routine-p">
                  Wow Skin Science is a top brand in India and around the globe,
                  known for using natural ingredients and avoiding harsh
                  chemicals, providing safe and effective skincare and haircare
                  solutions.
                </p>
              </div>
              <div className="w-col w-col-6">
                <h4 className="heading text-center ">Anti UI Packaging</h4>
                <Image
                  className="reason-img"
                  src={Ingredients7}
                  width={637}
                  height={510}
                />
                <p className="routine-p">
                  Experience the maximum effectiveness of Wow Skin Science's
                  natural ingredients by protecting them with premium packaging
                  that blocks out harmful UV rays and preserves their purity.
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-2">
            {id === "apple-cider-vinegar-shampoo-set" ||
            "rosemary-oil-shampoo-and-hair-conditioner-combo" ? (
              <h4 className="h-heading pt-4">
                Clean Ingredients Means Healthier Scalp & Hair
              </h4>
            ) : (
              <h4 className="h-heading pt-4">
                Clean Ingredients Means Healthy and Radiant Skin
              </h4>
            )}
            <h2 className="h-sub-heading">
              Why Over 1 Million People Have Switched to Wow Skin Science Hair
              Products
            </h2>
          </div>
          <div className="w-container reasons-top">
            <div className="w-row">
              <div className="w-col w-col-6">
                <h4 className="heading text-center">Clean and Non-Toxic</h4>
                <Image
                  className="reason-img"
                  src={
                    id === "apple-cider-vinegar-shampoo-set" ||
                    "rosemary-oil-shampoo-and-hair-conditioner-combo"
                      ? Ingredients1
                      : Ingredients5
                  }
                  width={637}
                  height={510}
                />
                <p className="routine-p">
                  Wow Skin Science products are non-toxic, chemical-free, and
                  safe for both you and the environment. Using natural
                  ingredients such as plant-based oils and extracts, natural
                  fragrances, and biodegradable surfactants, our products are
                  effective and gentle for daily use.
                </p>
              </div>
              <div className="w-col w-col-6">
                <h4 className="heading text-center ">Nature-inspired</h4>
                <Image
                  className="reason-img"
                  src={Ingredients2}
                  width={637}
                  height={510}
                />
                <p className="routine-p">
                  Experience the power of natural ingredients with Wow Skin
                  Science's plant-based hair care products, sourced from the
                  Himalayans for maximum efficacy. Say goodbye to harsh
                  chemicals and hello to effective, safe hair care.
                </p>
              </div>
            </div>
            <div className="w-row">
              <div className="w-col w-col-6">
                <h4 className="heading text-center ">Trusted Brand</h4>
                {/* <Image
                  className="reason-img"
                  src={imageSrc}
                  width={637}
                  height={510}
                /> */}
                <Image
                  className="reason-img"
                  src={
                    id === "onion-combo-kit" ||
                    "onion-complete-hair-care-kit"
                      ? TrustedBrandOnion
                      : imageSrc
                  }
                  width={637}
                  height={510}
                />
                
               
                <p className="routine-p">
                  Wow Skin Science is a top brand in India and around the globe,
                  known for using natural ingredients and avoiding harsh
                  chemicals, providing safe and effective skincare and haircare
                  solutions.
                </p>
              </div>
              <div className="w-col w-col-6">
                <h4 className="heading text-center ">Amber Packaging</h4>
                <Image
                  className="reason-img"
                  src={Ingredients4}
                  width={637}
                  height={510}
                />
                <p className="routine-p">
                  Experience the maximum effectiveness of Wow Skin Science's
                  natural ingredients by protecting them with Amber packaging
                  that blocks out harmful UV rays and preserves their purity.
                </p>
              </div>
            </div>
          </div>
        </>
      )}



    </>
  );
};

export default IngredientSpecifications;
