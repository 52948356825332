import Image from "next/image";
import React from "react";
import ALink from "~/components/features/custom-link";
import Shampoo from "~/public/images/shampoo.jpeg";
import Conditioner from "~/public/images/conditioner.jpeg";
import FaceWash from "~/public/images/facewash.png";
import { scrollWithOffset } from "~/utils/helper";
import AloeVeraFw from "~/public/images/face-wash.jpeg";
import serum from "~/public/images/serum.jpeg";
import RosemaryConditioner from "~/public/images/subpages/rosemary-conditioner.jpeg";
import RosemaryOil from "~/public/images/subpages/rosemary-oil.jpeg";
import RosemarySerum from "~/public/images/subpages/rosemary-serum.jpeg";
import RosemaryShampoo from "~/public/images/subpages/rosemary-shampoo.jpeg";

const KeySpecifications = ({ id }) => {
  const onButtonClick = () => {
    scrollWithOffset("product-details", 10, (ele) => {
      ele.click();
    });
  };

  return (
    <>
      {id === "apple-cider-vinegar-shampoo-set" && (
        <div className="container" id="ingredients">
          <div className="w-container">
            <div className="product-list-content w-row">
              <div className="w-col w-col-4">
                <h3 className="heading">Apple Cider Vinegar Shampoo</h3>
                <ALink href="#" className="w-inline-block">
                  <Image src={Shampoo} height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img src="/images/home/apple.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Apple Cider Vinegar </strong>- Apple cider
                        vinegar has been used for centuries as a natural remedy
                        for a variety of health and beauty concerns, including
                        healthy hair. This type of vinegar is made from
                        fermented apple juice, and contains a number of
                        beneficial compounds, such as acetic acid, vitamins, and
                        minerals.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/argan.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Argan Oil </strong>- The shampoo, enriched with
                        Argan Oil, nurtures hair with its rich content of
                        Vitamin E and fatty acids. This powerhouse ingredient
                        strengthens, moisturizes, and imparts a healthy shine,
                        ensuring your hair receives optimal care for a radiant
                        and revitalized look.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/almond.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Sweet Almond Oil </strong>- Almond oil is a
                        natural oil that is derived from almonds. It helps on
                        moisturizing the hair and scalp, which can help to
                        prevent dryness and flakiness, and improve the overall
                        health and appearance of the hair.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row">
              <div className="w-col w-col-4">
                <h3 className="heading">Apple Cider Vinegar Conditioner</h3>
                <ALink href="#" className="w-inline-block">
                  <Image src={Conditioner} height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img src="/images/home/apple.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Apple Cider Vinegar </strong>- Hair conditioner
                        harnesses the conditioning prowess of apple cider
                        vinegar. Its acidic nature helps smoothen hair cuticles,
                        detangle strands, and balance pH levels, leaving your
                        hair soft, manageable, and with a natural shine.
                        Experience the wholesome care of ACV for luscious,
                        well-nourished locks.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/coconut.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Coconut Oil </strong>- Enriched with coconut
                        oil, provides deep nourishment. Coconut oil's fatty
                        acids penetrate the hair shaft, promoting moisture
                        retention, reducing split ends, and enhancing overall
                        hair health. Experience the luxurious blend that leaves
                        your hair silky, manageable, and naturally radiant.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/argan.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Argan Oil </strong>- Featuring the goodness of
                        argan oil, enriches hair with its high Vitamin E and
                        essential fatty acid content. This powerhouse ingredient
                        provides intense hydration, promotes shine, and
                        contributes to overall hair health, leaving your locks
                        smooth, nourished, and beautifully revitalized.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ALink
              href="#"
              className="button-product-ingredients w-button"
              onClick={onButtonClick}
            >
              I'm Ready for Healthier Hair & Scalp →{" "}
            </ALink>
          </div>
        </div>
      )}

      {id === "apple-cider-vinegar-shampoo-set-2" && (
        <div className="container" id="ingredients">
          <div className="w-container">
            <div className="product-list-content w-row">
              <div className="w-col w-col-4">
                <h3 className="heading">Apple Cider Vinegar Shampoo</h3>
                <ALink href="#" className="w-inline-block">
                  <Image src={Shampoo} height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img src="/images/home/apple.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Apple Cider Vinegar </strong>- Apple cider
                        vinegar has been used for centuries as a natural remedy
                        for a variety of health and beauty concerns, including
                        healthy hair. This type of vinegar is made from
                        fermented apple juice, and contains a number of
                        beneficial compounds, such as acetic acid, vitamins, and
                        minerals.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/argan.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Argan Oil </strong>- The shampoo, enriched with
                        Argan Oil, nurtures hair with its rich content of
                        Vitamin E and fatty acids. This powerhouse ingredient
                        strengthens, moisturizes, and imparts a healthy shine,
                        ensuring your hair receives optimal care for a radiant
                        and revitalized look.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/almond.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Sweet Almond Oil </strong>- Almond oil is a
                        natural oil that is derived from almonds. It helps on
                        moisturizing the hair and scalp, which can help to
                        prevent dryness and flakiness, and improve the overall
                        health and appearance of the hair.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row">
              <div className="w-col w-col-4">
                <h3 className="heading">Apple Cider Vinegar Conditioner</h3>
                <ALink href="#" className="w-inline-block">
                  <Image src={Conditioner} height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img src="/images/home/apple.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Apple Cider Vinegar </strong>- Hair conditioner
                        harnesses the conditioning prowess of apple cider
                        vinegar. Its acidic nature helps smoothen hair cuticles,
                        detangle strands, and balance pH levels, leaving your
                        hair soft, manageable, and with a natural shine.
                        Experience the wholesome care of ACV for luscious,
                        well-nourished locks.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/coconut.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Coconut Oil </strong>- Enriched with coconut
                        oil, provides deep nourishment. Coconut oil's fatty
                        acids penetrate the hair shaft, promoting moisture
                        retention, reducing split ends, and enhancing overall
                        hair health. Experience the luxurious blend that leaves
                        your hair silky, manageable, and naturally radiant.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/argan.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Argan Oil </strong>- Featuring the goodness of
                        argan oil, enriches hair with its high Vitamin E and
                        essential fatty acid content. This powerhouse ingredient
                        provides intense hydration, promotes shine, and
                        contributes to overall hair health, leaving your locks
                        smooth, nourished, and beautifully revitalized.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ALink
              href="#"
              className="button-product-ingredients w-button"
              onClick={onButtonClick}
            >
              I'm Ready for Healthier Hair & Scalp →{" "}
            </ALink>
          </div>
        </div>
      )}

      {id === "apple-cider-vinegar-face-wash" && (
        <div className="rec-section new" id="ingredients">
          <div className="container-55">
            <div className="gp-container advantages w-container-1">
              <div className="advantage advantage-1">
                <img
                  src="/images/home/apple.png"
                  loading="lazy"
                  sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                  alt=""
                  class="new-ing-section-img"
                />
                <h5 className="mb-2 mt-2">Apple Cider Vinegar</h5>
                <div className="text-block">
                  Renowned for its antibacterial and antifungal properties,
                  apple cider vinegar balances skin pH, reduces acne, and
                  clarifies complexion, promoting clear, healthy skin.
                </div>
              </div>
              <div className="advantage">
                <img
                  src="/images/home/aloe-vera.png"
                  loading="lazy"
                  sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                  alt=""
                  class="new-ing-section-img"
                />{" "}
                <h5 className="mb-2 mt-2">Aloe Vera Extract</h5>
                <div className="text-block">
                  Aloe vera's soothing properties calm inflammation, hydrate,
                  and nourish the skin. It accelerates healing, making it an
                  ideal ingredient for soothing and revitalizing the complexion.
                </div>
              </div>
              <div className="advantage">
                <img
                  src="/images/home/hyaluronic.png"
                  loading="lazy"
                  sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                  alt=""
                  class="new-ing-section-img"
                />{" "}
                <h5 className="mb-2 mt-2">Hyaluronic Acid</h5>
                <div className="text-block">
                  Known for its exceptional hydrating abilities, hyaluronic acid
                  locks in moisture, plumps the skin, and reduces the appearance
                  of fine lines, leaving the skin looking youthful and supple.
                </div>
              </div>
            </div>
            <div className="gp-container advantages w-container-1">
              <div className="advantage">
                <img
                  src="/images/home/b5.png"
                  loading="lazy"
                  sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                  alt=""
                  class="new-ing-section-img"
                />{" "}
                <h5 className="mb-2 mt-2">Vitamin B5</h5>
                <div className="text-block">
                  Vitamin B5, or panthenol, deeply moisturizes and soothes the
                  skin. It enhances skin elasticity, promotes wound healing, and
                  contributes to a smoother, healthier complexion.
                </div>
              </div>
              <div className="advantage">
                <img
                  src="/images/home/vite.png"
                  loading="lazy"
                  sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                  alt=""
                  class="new-ing-section-img"
                />{" "}
                <h5 className="mb-2 mt-2">Vitamin E</h5>
                <div className="text-block">
                  Vitamin E, a key component in ACV skincare, is a potent
                  antioxidant renowned for its skin-nourishing benefits. It
                  shields against free radicals, reducing oxidative stress and
                  preventing premature aging. Vitamin E supports skin
                  elasticity, promoting a youthful complexion.
                </div>
              </div>
              <div className="advantage">
                <img
                  src="/images/home/shea-butter.png"
                  loading="lazy"
                  sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                  alt=""
                  class="new-ing-section-img"
                />{" "}
                <h5 className="mb-2 mt-2">Shea Butter</h5>
                <div className="text-block">
                  Shea Butter has moisturizing, anti-inflammatory properties and
                  rich in vitamins A & E that help to revive dry scalp and hair,
                  protect strands from damage caused by sun, strengthen weak,
                  brittle strands.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {id === "apple-cider-vinegar-face-wash-set2" && (
        <div className="w-container">
          <div className="product-list-content w-row">
            <div className="w-col w-col-4">
              <ALink href="#" className="w-inline-block">
                <Image src={FaceWash} height={697} width={697} />
              </ALink>
            </div>
            <div className="w-col w-col-8">
              <div className="rich-text-block w-richtext pt-4">
                <h5 className="mb-2">Key Benefits:</h5>
                <ul role="list">
                  <li className="mt-2 mb-3">
                    <strong>Intense Cleansing and Nourishment.</strong>{" "}
                    Specially formulated to provide intense cleansing,
                    effectively removing dirt, sweat, excess oil, and makeup. It
                    nourishes the skin with the goodness of certified apple
                    cider vinegar, aloe vera extract, hyaluronic acid, and
                    vitamin B5.
                  </li>
                  <li className="mt-2 mb-3">
                    <strong>Balanced pH and Purified Pores.</strong> It helps in
                    restoring the skin’s natural pH balance, promoting overall
                    skin health. The formulation purifies pores, diffuses
                    impurities, and tightens open pores, contributing to a
                    clean, clear, and radiant complexion.
                  </li>
                  <li className="mt-2 mb-3">
                    <strong>Reduction of Skin Issues.</strong> It is known for
                    its effectiveness in reducing common skin issues such as
                    blackheads, pigmentation, and acne. The rich foam dissolves
                    and washes away dead skin cells, leaving your skin visibly
                    clearer, smoother, and glowing.
                  </li>
                  <li className="mt-2 mb-3">
                    <strong>Enhanced Skin Elasticity and Hydration.</strong> The
                    face wash not only balances essential skin oils but also
                    enhances the elasticity of your skin. It provides intensive
                    hydration, promoting the skin's ability to retain moisture.
                    This results in softer, more supple skin with a brighter and
                    healthier appearance.
                  </li>
                </ul>
                <h5 className="mb-2">Key Ingredients:</h5>
                <p
                  className="mb-2"
                  style={{ "font-size": "15px", "line-height": "inherit" }}
                >
                  <strong>Apple Cider Vinegar</strong> - Renowned for its
                  antibacterial and antifungal properties, apple cider vinegar
                  balances skin pH, reduces acne, and clarifies complexion,
                  promoting clear, healthy skin.
                </p>
                <p
                  className="mb-2"
                  style={{ "font-size": "15px", "line-height": "inherit" }}
                >
                  <strong>Aloe Vera Extract</strong> - Aloe vera's soothing
                  properties calm inflammation, hydrate, and nourish the skin.
                  It accelerates healing, making it an ideal ingredient for
                  soothing and revitalizing the complexion.
                </p>
                <p
                  className="mb-2"
                  style={{ "font-size": "15px", "line-height": "inherit" }}
                >
                  <strong>Hyaluronic Acid</strong> - Known for its exceptional
                  hydrating abilities, hyaluronic acid locks in moisture, plumps
                  the skin, and reduces the appearance of fine lines, leaving
                  the skin looking youthful and supple.
                </p>
                <p
                  className="mb-2"
                  style={{ "font-size": "15px", "line-height": "inherit" }}
                >
                  <strong>Vitamin B5</strong> - Vitamin B5, or panthenol, deeply
                  moisturizes and soothes the skin. It enhances skin elasticity,
                  promotes wound healing, and contributes to a smoother,
                  healthier complexion.
                </p>
              </div>
            </div>
          </div>

          <ALink
            href="#"
            className="button-product-ingredients w-button"
            onClick={onButtonClick}
          >
            I'm Ready for Healthier and Radiant Skin →{" "}
          </ALink>
        </div>
      )}

      {id === "aloe-vera-face-wash" && (
        <div className="container" id="ingredients">
          <div className="w-container">
            <div className="product-list-content w-row">
              <div className="w-col w-col-4">
                <h4>Aloe Vera Face Wash</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src={AloeVeraFw} height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/aloe-vera.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Aloe Vera </strong>- Rich in moisture and
                        nutrients, it hydrates the skin, prevents moisture loss,
                        and supports skin repair.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/greentea.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Green Tea </strong>- Abundant in antioxidants,
                        it neutralizes free radicals, protects the skin from
                        damage, and promotes youthful skin.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/cucumber.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Cucumber </strong>- Contains vitamin C, silica,
                        and caffeic acid, providing antioxidant protection,
                        supporting skin rejuvenation, and neutralizing free
                        radicals.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/sunflower.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Calendula </strong>- Contains oleanic acid and
                        quercetin, delivering antioxidant protection, calming
                        irritations, and reducing skin discomfort.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <ALink
              href="#"
              className="button-product-ingredients w-button"
              onClick={onButtonClick}
            >
              I'm Ready for Healthier and Radiant Skin →{" "}
            </ALink>
          </div>
        </div>
      )}

      {id === "anti-acne-face-serum" && (
        <div className="container" id="ingredients">
          <div className="w-container">
            <div className="product-list-content w-row">
              <div className="w-col w-col-4">
                <h4>Anti Acne Serum Key Ingredients</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src={serum} height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img src="/images/home/neem.png" className="key-img" />
                      <p>
                        <strong>Neem Leaf Oil:</strong> <br />
                        <br />
                        <strong>
                          <em>Special Qualities:</em>
                        </strong>{" "}
                        Neem is renowned for its antibacterial properties,
                        effectively combating acne and promoting skin health.
                        <br />
                        <br />
                        <strong>
                          ‍<em>Benefits:</em>{" "}
                        </strong>
                        Reduces inflammation, controls sebum production, and
                        aids in healing acne scars.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/tea.png" className="key-img" />
                      <p>
                        <strong>Tea Tree Oil:</strong>
                        <br />
                        <br />
                        <strong>
                          <em>Special Qualities:</em>{" "}
                        </strong>
                        A powerful antimicrobial with natural astringent
                        properties.
                        <br />
                        <br />
                        <strong>
                          ‍<em>Benefits:</em>{" "}
                        </strong>
                        Fights acne-causing bacteria, soothes irritation, and
                        promotes clear, healthy skin.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/lime.png" className="key-img" />
                      <p>
                        <strong>
                          Caviar Lime Extract:
                          <br />
                          <br />
                        </strong>
                        <strong>
                          <em>Special Qualities:</em>
                        </strong>{" "}
                        Rich in alpha hydroxy acids (AHAs) for gentle
                        exfoliation.
                        <br />
                        <br />
                        <strong>
                          ‍<em>Benefits:</em>{" "}
                        </strong>
                        Boosts skin renewal, minimizes pores, and enhances
                        overall skin texture for a radiant complexion.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <ALink
              href="#"
              className="button-product-ingredients w-button"
              onClick={onButtonClick}
            >
              I'm Ready for Glowy and Flawless Skin →{" "}
            </ALink>
          </div>
        </div>
      )}

      {id === "vitamin-c-and-niacinamide-face-wash" && (
        <>
          <div className="rec-section new" id="ingredients">
            <div className="container-55">
              <div className="gp-container advantages w-container-1">
                <div className="advantage advantage-1">
                  <img
                    src="/images/home/vitc.png"
                    loading="lazy"
                    sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                    alt=""
                    class="new-ing-section-img"
                  />
                  <h5 className="mb-2 mt-2">Vitamin C</h5>
                  <div className="text-block">
                    A potent antioxidant, Vitamin C brightens and revitalizes
                    the skin, promoting a radiant complexion. It helps reduce
                    signs of aging, neutralizing free radicals and supporting
                    collagen production.
                  </div>
                </div>
                <div className="advantage">
                  <img
                    src="/images/home/niacinamide.png"
                    loading="lazy"
                    sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                    alt=""
                    class="new-ing-section-img"
                  />{" "}
                  <h5 className="mb-2 mt-2">Niacinamide</h5>
                  <div className="text-block">
                    Known as Vitamin B3, it nourishes the skin, addressing
                    issues like uneven tone, fine lines, and enlarged pores. It
                    enhances the skin's barrier function, promoting a healthier
                    and smoother appearance.
                  </div>
                </div>
                <div className="advantage">
                  <img
                    src="/images/home/lemon.png"
                    loading="lazy"
                    sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                    alt=""
                    class="new-ing-section-img"
                  />{" "}
                  <h5 className="mb-2 mt-2">Lemon Essential Oil</h5>
                  <div className="text-block">
                    Packed with Vitamin C, lemon essential oil provides
                    antioxidants that help rejuvenate and clarify the skin. It
                    contributes to a fresh and vibrant complexion.
                  </div>
                </div>
              </div>
              <div className="gp-container advantages w-container-1">
                <div className="advantage">
                  <img
                    src="/images/home/orange.png"
                    loading="lazy"
                    sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                    alt=""
                    class="new-ing-section-img"
                  />{" "}
                  <h5 className="mb-2 mt-2">Orange Essential Oil</h5>
                  <div className="text-block">
                    Rich in Vitamin C, orange essential oil imparts
                    anti-inflammatory and antioxidant benefits. It promotes a
                    brighter skin tone and revitalizes dull-looking skin.
                  </div>
                </div>
                <div className="advantage">
                  <img
                    src="/images/home/liqurice.png"
                    loading="lazy"
                    sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                    alt=""
                    class="new-ing-section-img"
                  />{" "}
                  <h5 className="mb-2 mt-2">Liquorice & Mulberry Extracts</h5>
                  <div className="text-block">
                    These botanical extracts act as melanin blockers, regulating
                    pigment production to reduce dark spots and promote an even
                    skin tone. They contribute to a clearer and more radiant
                    complexion.
                  </div>
                </div>
                <div className="advantage">
                  <img
                    src="/images/home/hyaluronic.png"
                    loading="lazy"
                    sizes="(max-width: 991px) 190px, (max-width: 1439px) 27vw, 270px"
                    alt=""
                    class="new-ing-section-img"
                  />{" "}
                  <h5 className="mb-2 mt-2">Hyaluronic Acid</h5>
                  <div className="text-block">
                    Known for its hydrating properties, hyaluronic acid helps
                    retain moisture, keeping the skin plump and supple. It
                    supports the skin's collagen structure, contributing to a
                    smoother and more youthful appearance.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {id === "rosemary-oil-shampoo-and-hair-conditioner-combo" && (
        <div className="container" id="ingredients">
          <div className="w-container">
            <div className="product-list-content w-row">
              <div className="w-col w-col-4">
                <h4>Rosemary with Biotin Shampoo</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src={RosemaryShampoo} height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/rosemary.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Rosemary Oil </strong>- Rosemary oil, rich in
                        manganese and vitamin C, stimulates roots, soothes the
                        scalp, and prevents hair loss, promoting a healthier,
                        revitalized mane.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/biotin.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Biotin </strong>- Biotin forms a protective
                        layer, minimizing breakage and split ends, promoting
                        stronger, resilient strands for reduced hair thinning
                        and fall.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/moringa.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Moringa Leaf Extract </strong>- Moringa leaf
                        extract, packed with antioxidants, strengthens roots,
                        reducing hair loss, and contributes to overall hair
                        health for a fuller, glossier appearance.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/olive.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Olive Oil </strong>- Olive oil nourishes and
                        moisturizes, enhancing hair structure, elasticity, and
                        root strength. Leaves hair soft, manageable, and
                        resilient with a natural shine.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/lavender.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Lavender Extract </strong>- Lavender extract
                        soothes and moisturizes, providing essential nutrients
                        to the scalp. Contributes to denser, healthier hair with
                        a natural shine and improved texture.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/vetiver.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Vetiver Root Oil </strong>- Vetiver root oil
                        adds a calming fragrance while contributing to overall
                        hair health. Its nourishing properties leave hair
                        smooth, shiny, and irresistibly attractive.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row mt-10">
              <div className="w-col w-col-4">
                <h4>Rosemary with Biotin Conditioner</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src={RosemaryConditioner} height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/lavender.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Rosemary Oil </strong>- Rosemary oil, rich in
                        antioxidants, revitalizes hair, stimulates the scalp,
                        and promotes a healthier appearance, leaving strands
                        soft and manageable with a natural shine.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/biotin.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Biotin </strong>- Biotin fortifies strands,
                        minimizing breakage, and split ends. It adds strength
                        and resilience to the hair, reducing thinning and
                        promoting overall hair health.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/coconut.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Coconut oil </strong>- Coconut oil deeply
                        nourishes and conditions, adding moisture and shine. It
                        repairs damaged hair, leaving it smooth, soft, and with
                        a tropical, delightful fragrance.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/shea.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Shea Butter </strong>- Shea butter provides
                        intensive moisture, promoting softness and
                        manageability. It deeply conditions, leaving hair with a
                        luxurious texture, improved elasticity, and a healthy
                        sheen.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/babassu.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Babassu Oil </strong>- Babassu seed oil, rich in
                        fatty acids, nourishes and hydrates. It adds shine,
                        softness, and improves the texture of hair, leaving it
                        silky and well-nourished.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row mt-10">
              <div className="w-col w-col-4">
                <h4>Rosemary with Biotin Hair Oil</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src={RosemaryOil} height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/lavender.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Rosemary Oil </strong>- Rosemary oil, rich in
                        antioxidants, stimulates hair growth, soothes the scalp,
                        and adds shine. It nourishes and revitalizes, promoting
                        healthier, stronger strands.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/biotin.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Biotin </strong>- Biotin strengthens hair,
                        reducing breakage and thinning. It adds a protective
                        layer, promoting resilient, thicker strands with
                        improved texture and overall health.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/sesame.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Sesame Seed Oil </strong>- Sesame seed oil
                        nourishes and conditions, deeply penetrating the hair
                        shaft. It adds luster, softness, and improves the
                        manageability of the hair.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/olive.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Olive Oil </strong>- Olive oil, a natural
                        emollient, moisturizes and nourishes hair. It enhances
                        elasticity, adds shine, and promotes a healthier, more
                        resilient mane.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/castor.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Castor Oil </strong>- Castor oil promotes hair
                        growth, strengthens roots, and conditions the scalp. It
                        adds thickness and shine, leaving hair smooth, soft, and
                        more manageable.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/amla.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Amla Oil </strong> - Vetiver root oil adds a
                        calming fragrance while contributing to overall hair
                        health. Its nourishing properties leave hair smooth,
                        shiny, and irresistibly attractive.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row mt-10">
              <div className="w-col w-col-4">
                <h4>Rosemary with Biotin Hair Serum</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src={RosemarySerum} height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/lavender.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Rosemary Leaf Extract </strong>- Rosemary leaf
                        extract stimulates hair growth, nourishing the scalp. It
                        adds shine, improves texture, and revitalizes strands,
                        promoting a healthier, lustrous appearance.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/home/biotin.png" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Biotin </strong>- Biotin strengthens and
                        fortifies hair, reducing breakage and promoting thicker,
                        healthier strands. It contributes to improved texture
                        and overall hair health.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/green-leaf.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Green Tea Leaf Extract </strong>- Green tea leaf
                        extract, rich in antioxidants, nourishes hair and
                        stimulates the scalp. It adds shine, reduces hair fall,
                        and contributes to a revitalized, healthier appearance.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/blueberry.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Blueberry Extract </strong>- Blueberry extract,
                        packed with vitamins and antioxidants, enhances hair
                        health. It adds luster, strengthens strands, and
                        contributes to a smoother, shinier appearance.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img
                        src="/images/home/bhringraj.png"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Bhringraj Extract </strong>- Bhringraj extract
                        promotes hair growth and reduces hair fall. It nourishes
                        the scalp, improves texture, and contributes to
                        stronger, healthier, and more vibrant hair.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <ALink
              href="#"
              className="button-product-ingredients w-button"
              onClick={onButtonClick}
            >
              I'm Ready for Brighter and Rejuvenated Skin →{" "}
            </ALink>
          </div>
        </div>
      )}

{id === "onion-combo-kit" && (
        <div className="container" id="ingredients">
          <div className="w-container">
            <div className="product-list-content w-row">
              <div className="w-col w-col-4">
                <h4>Red Onion Black Seed Oil Shampoo</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src="/images/onionKit/onion-shampoo.png" height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/onionKit/onion-shampoo-ing1.jpg"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Red Onion Seed Oil </strong>- Promotes robust hair growth, controls hair fall, 
                        improves circulation to the scalp, and cleanses buildup.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/onion-shampoo-ing2.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Vitamn B5  </strong>- Nourishes and moisturizes the hair, enhancing strand quality, 
                        reducing frizz, and adding natural shine.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/onion-shampoo-ing3.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Black Seed Oil Extract  </strong>- Strengthens hair, reduces dandruff, and promotes 
                        a healthy scalp. Its antioxidants contribute to overall hair health and shine
                      </p>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row mt-10">
              <div className="w-col w-col-4">
                <h4>Red Onion Black Seed Oil Conditioner</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src="/images/onionKit/Conditioner.png" height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/onionKit/onion-shampoo-ing1.jpg"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Red Onion Seed Oil </strong>- Forms a protective barrier, delivers hydration, and contributes to softer, 
                        protected hair with a transformed texture.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/conditioner-wheat.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Wheat Protein </strong>- Strengthens hair, adds volume, and helps in repairing damaged strands, leaving the hair 
                        smoother and more manageable.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/onion-shampoo-ing3.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Black Seed Oil Extract </strong>- Nourishes the scalp, reduces dandruff, and contributes to overall hair 
                        health, providing strength and a healthy appearance.
                      </p>
                    </li>
                     
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row mt-10">
              <div className="w-col w-col-4">
                <h4>Red Onion Black Seed Oil Hair OIl</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src="/images/onionKit/Hair-oil.png" height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/onionKit/onion-shampoo-ing1.jpg"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Red Onion Seed Oil </strong>- Boosts scalp blood circulation, seals hair cuticles, 
                        and restores natural shine and softness to the locks.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/onion-shampoo-ing3.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Black Seed Oil Extract </strong>- Nourishes the scalp, reduces dandruff, and contributes to overall hair health, providing 
                        strength and a healthy appearance.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/castor-oil.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Castor Oil </strong>- Strengthens hair, promotes hair growth, and prevents breakage.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/almond-oil.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Almond Oil </strong>- Nourishes and moisturizes the hair, 
                        making it softer and more manageable.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/jojoba-oil.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Jojoba Oil </strong>- Moisturizes the scalp, promotes hair thickness, 
                        and reduces dryness.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/argan-oil.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Argan Oil </strong> - Adds shine, tames frizz, and deeply conditions the hair, 
                        contributing to a healthier and more vibrant appearance.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row mt-10">
              <div className="w-col w-col-4">
                <h4>Red Onion Black Seed Oil Hair Mask</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src="/images/onionKit/HairMask.png" height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/onionKit/onion-shampoo-ing1.jpg"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Red Onion Seed Oil </strong>- Promotes hair growth, reduces dandruff, and 
                        enhances overall hair health with its nourishing and circulation-boosting properties.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/onion-shampoo-ing3.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Black Seed Oil Extract </strong>- Contributes to a healthy scalp, reduces dandruff, 
                        and strengthens the hair, promoting a lustrous and revitalized appearance.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img
                        src="/images/onionKit/olive-oil.jpg"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Olive Oil </strong>- Nourishes and moisturizes the hair, providing deep hydration 
                        and improving manageability.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img
                        src="/images/onionKit/sheabutter.jpg"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Shea Butter </strong>- Conditions and softens the hair, helping to repair and protect against damage, 
                        leaving it smooth, shiny, and more resilient.
                      </p>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>

            <ALink
              href="#"
              className="button-product-ingredients w-button"
              onClick={onButtonClick}
            >
              I'm Ready for Brighter and Rejuvenated Skin →{" "}
            </ALink>
          </div>
        </div>
      )}


{id === "onion-complete-hair-care-kit" && (
        <div className="container" id="ingredients">
          <div className="w-container">
            <div className="product-list-content w-row">
              <div className="w-col w-col-4">
                <h4>Red Onion Black Seed Oil Shampoo</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src="/images/onionKit/onion-shampoo-300ml.jpg" height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/onionKit/onion-shampoo-ing1.jpg"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>RED ONION SEED OIL EXTRACT </strong>- Sulfur and potassium strengthens hair,
                         prevents breakage and hair thinning.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/onion-shampoo-ing2.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>PRO-VITAMIN B5 </strong>- Strengthens roots, boosts hydration and makes hair softer and stronger.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/onion-shampoo-ing3.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>BLACK SEED OIL</strong>- The antioxidant properties soothes the scalp and reduces hair fall.
                      </p>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row mt-10">
              <div className="w-col w-col-4">
                <h4>Red Onion Black Seed Oil Conditioner</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src="/images/onionKit/onion-conditioner-300ml.jpg" height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/onionKit/onion-shampoo-ing1.jpg"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>RED ONION SEED OIL EXTRACT </strong>- Sulfur and potassium strengthens hair, prevents breakage and hair thinning.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/conditioner-wheat.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>WHEAT PROTEIN</strong>- Derived from wheat kernels, provides hydration, repairs damaged hair and reduces static.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/onion-shampoo-ing3.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>BLACK SEED OIL</strong>- The antioxidant properties soothes the scalp and reduces hair fall.
                      </p>
                    </li>
                     
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row mt-10">
              <div className="w-col w-col-4">
                <h4>Red Onion Black Seed Oil Hair OIl</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src="/images/onionKit/onion-hair-oil-200ml.jpg" height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/onionKit/onion-shampoo-ing1.jpg"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>ONION SEED OIL </strong>- Rich in sulfur and potassium, it strengthens hair, prevents breakage and hair thinning.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/onion-shampoo-ing3.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>BLACK SEED OIL</strong>- The antioxidant properties soothes the scalp and reduces hair fall.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/castor-oil.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Castor Oil </strong>- Strengthens hair, promotes hair growth, and prevents breakage.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/almond-oil.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Almond Oil </strong>- Nourishes and moisturizes the hair, 
                        making it softer and more manageable.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/jojoba-oil.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Jojoba Oil </strong>- Moisturizes the scalp, promotes hair thickness, 
                        and reduces dryness.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/argan-oil.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Argan Oil </strong> - Adds shine, tames frizz, and deeply conditions the hair, 
                        contributing to a healthier and more vibrant appearance.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-list-content w-row mt-10">
              <div className="w-col w-col-4">
                <h4>Red Onion Black Seed Oil Hair Serum</h4>
                <ALink href="#" className="w-inline-block">
                  <Image src="/images/onionKit/onion-serum.jpg" height={697} width={697} />
                </ALink>
              </div>
              <div className="w-col w-col-8">
                <div className="rich-text-block w-richtext pt-4">
                  <ul role="list" className="features-right-2 w-list-unstyled">
                    <li className="features-block-two-2">
                      <img
                        src="/images/onionKit/onion-shampoo-ing1.jpg"
                        className="key-img"
                      />
                      <p className="ml-3 mb-0">
                        <strong>Red Onion Seed Oil </strong>- Promotes hair growth, reduces dandruff, and 
                        enhances overall hair health with its nourishing and circulation-boosting properties.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/onion-shampoo-ing3.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Black Seed Oil Extract </strong>- Contributes to a healthy scalp, reduces dandruff, 
                        and strengthens the hair, promoting a lustrous and revitalized appearance.
                      </p>
                    </li>
                    <li className="features-block-two-2">
                      <img src="/images/onionKit/jojoba-oil.jpg" className="key-img" />
                      <p className="ml-3 mb-0">
                        <strong>Jojoba Oil </strong>- Moisturizes the scalp, promotes hair thickness, 
                        and reduces dryness.
                      </p>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>

            <ALink
              href="#"
              className="button-product-ingredients w-button"
              onClick={onButtonClick}
            >
              I'm Ready for Brighter and Rejuvenated Skin →{" "}
            </ALink>
          </div>
        </div>
      )}

    </>
  );
};

export default KeySpecifications;
