import React from "react";
import ALink from "~/components/features/custom-link";
import { scrollWithOffset } from "~/utils/helper";

const ProductIntro = ({ data: item, id }) => {
  const onButtonClick = () => {
    scrollWithOffset("product-details", 10, (ele) => {
      ele.click();
    });
  };

  let descriptionClassName;

  switch (id) {
    case "aloe-vera-face-wash":
      descriptionClassName = "description-aloe-page-padding";
      break;
    case "anti-acne-face-serum":
    case "vitamin-c-and-niacinamide-face-wash":
      descriptionClassName = "description-page-padding wrap";
      break;
    case "rosemary-oil-shampoo-and-hair-conditioner-combo":
      descriptionClassName = "description-page-padding wrap";
      break;
    case "onion-combo-kit":
      descriptionClassName = "description-page-padding wrap";
      break;
    case "onion-complete-hair-care-kit":
      descriptionClassName = "description-page-padding wrap";
      break;
    default:
      descriptionClassName = "description-page-padding";
      break;
  }

  return (
    <div className={descriptionClassName}>
      <div className="desc-container">
        <div className="descrition-heading">
          <div className="text-align-center">
            <div className="description-max-width">
              <h1 className="description-heading-text">{item.heading}</h1>
              <div className="max-width-desc align-items-center">
                <div
                  dangerouslySetInnerHTML={{ __html: item.description }}
                  className="description-text"
                />
              </div>
              <div className="d-flex justify-content-center">
                <ALink
                  href="#"
                  className="btn-parge uui-button button-primary-1 button-w"
                  onClick={onButtonClick}
                >
                  {item.buttonText}
                </ALink>
              </div>
            </div>
          </div>
          <div className="image-wrapper"></div>
        </div>
      </div>
    </div>
  );
};

export default ProductIntro;
