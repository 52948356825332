import Image from "next/image";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import ProductVariant from "~/components/partials/product/product-variant";
import MediaOne from "~/components/partials/product/media/media-one";
import security from "~/public/images/icons/security.png";
import delivery from "~/public/images/icons/delivery.png";
import payment from "~/public/images/icons/payment.png";
import { cartActions } from "~/store/cart";
import TimerComponent from "./countdown";
import useWindowDimensions from "~/utils/getWindowDimension";

const Details = (props) => {
  const { emptyCart, cartList, id, product } = props;

  const clearCartOnMount = async () => {
    if (cartList.length > 0) {
      try {
        await emptyCart();
      } catch (error) {}
    }
  };
  const { isSmallSize: isMobile } = useWindowDimensions();

  useEffect(() => {
    clearCartOnMount();
  }, []);

  const sizes = useMemo(
    () =>
      (product?.variants?.items || [])
        .sort((a, b) => b.position - a.position)
        .map((item) => ({ ...item })),
    [product?.variants?.items]
  );

  const title = product.title.toLowerCase();

  let content;
  switch (id) {
    case "vitamin-c-and-niacinamide-face-wash":
      content = (
        <p class="paragraph mb-2">
          ⭐️ ⭐️ ⭐️ ⭐️ ⭐️ Brightened <strong>500,000+</strong> glowing
          customers!
        </p>
      );
      break;
    case "rosemary-oil-shampoo-and-hair-conditioner-combo":
      content = (
        <p class="paragraph mb-2">
          ⭐️ ⭐️ ⭐️ ⭐️ ⭐️ Loved by <strong>400,000+ </strong>
          customers!
        </p>
      );
      break;
    default:
      content = (
        <p class="paragraph mb-2">
          ⭐️ ⭐️ ⭐️ ⭐️ ⭐️ Loved by{" "}
          <strong class="bold-text-30">600,000+</strong> customers worldwide
        </p>
      );
      break;
  }

  return (
    <div className="generic-top-desktop hero-section" id="product-details">
      <div className="header-slide w-row">
        <div className="column w-col w-col-6 slider-padding">
          <MediaOne key={`media-${product.id}`} product={product} />
        </div>
        <div className={`w-col w-col-6 ${isMobile ? "pt-0" : "pt-4"}`}>
          <h1 class="heading-2 mb-3 text-capitalize">{title}</h1>

          {content}
          <h5 class="sub-heading mb-1">Buy More, Save More + Freebies! </h5>

          <TimerComponent />
          <div className="wbs-section acv-multipack">
            <div className="wbs-container">
              <div className="wbs-section">
                {sizes.map((item) => (
                  <div key={item.id}>
                    <ProductVariant
                      item={item}
                      product={product}
                      id={id}
                      sizes={sizes}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="wbs-section-2">
            <div className="wbs-container">
              <div className="wbs-feature">
                <div className="wbs-feature-item m-auto">
                  <Image src={security} />
                  <div>
                    <h5 className="m-0">Safe and Secure</h5>
                    <p className="m-0">Checkout</p>
                  </div>
                </div>
                <div className="wbs-feature-item">
                  <Image src={delivery} />
                  <div>
                    <h5 className="m-0">Delivered in approx</h5>
                    <p className="m-0">3-5 days</p>
                  </div>
                </div>
                <div className="wbs-feature-item">
                  <Image src={payment} />
                  <div>
                    <h5 className="m-0">Online Payments</h5>
                    <p className="m-0">or COD</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cartList: state.cart.data || [],
  };
}

export default connect(mapStateToProps, {
  emptyCart: cartActions.emptyCart,
})(Details);
