import Image from "next/image";
import React from "react";
import ALink from "~/components/features/custom-link";
import Routine1 from "~/public/images/step1.jpeg";
import Routine2 from "~/public/images/step2.jpeg";
import RmaryRoutine1 from "~/public/images/subpages/rmary-routine1.jpeg";
import RmaryRoutine2 from "~/public/images/subpages/rmary-routine2.jpeg";
import RmaryRoutine3 from "~/public/images/subpages/rmary-routine3.jpeg";
import RmaryRoutine4 from "~/public/images/subpages/rmary-routine4.jpeg";
import VitCFw from "~/public/images/home/vticfw.webp";
import VitCSerum from "~/public/images/home/vitcserum.jpeg";
import ACVFW from "~/public/images/home/acvfw.jpeg";
import ACVFM from "~/public/images/home/acvfm.jpeg";

const Routine = (props) => {
  const { data, id } = props;

  return (
    <>
      <div className="heading-routine">
        <h3 className="heading">{data.heading}</h3>
        <h2 className="sub-heading"> {data.subHeading}</h2>
      </div>

      {id === "apple-cider-vinegar-shampoo-set" && (
        <div className="product-list-content w-container">
          <div className="w-row">
            <div className="w-col w-col-6">
              <h4 className="step-heading">Step 1:</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src={Routine1}
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
                Pour a generous dollop of ACV&nbsp;Shampoo onto your palm and
                massage it onto your scalp using only your fingertips. Leave on
                for 3 - 4 minutes. If your hair is especially oily or covered in
                dandruff, leave it on for an extra 1 - 2 minutes. Rinse
                thoroughly to make sure there’s no residue left behind.{" "}
                <strong>
                  Since it’s a powerful cleanser, use 1 - 2 times a week.{" "}
                </strong>
                Use the free scalp massager included on this package for extra
                build-up removal and refreshing scalp massage.
              </p>
            </div>
            <div className="w-col w-col-6">
              <h4 className="step-heading">Step 2:</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src={Routine2}
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
                Pour a dollop of Coconut/Avocado Oil conditioner on to your
                palm. Massage gently on to the length of damp hair till the
                ends. Avoid application on the roots, unless you have dry scalp
                &amp; hair.{" "}
                <strong>
                  Rinse it thoroughly with plenty of cold water to seal the
                  cuticles.{" "}
                </strong>
                Frequency of use depends on hair type/concern - please follow
                the instructions on packaging for better results.
              </p>
            </div>
          </div>
        </div>
      )}
      {id === "apple-cider-vinegar-shampoo-set-2" && (
        <div className="product-list-content w-container">
          <div className="w-row">
            <div className="w-col w-col-6">
              <h4 className="step-heading">Step 1:</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src={Routine1}
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
                Pour a generous dollop of ACV&nbsp;Shampoo onto your palm and
                massage it onto your scalp using only your fingertips. Leave on
                for 3 - 4 minutes. If your hair is especially oily or covered in
                dandruff, leave it on for an extra 1 - 2 minutes. Rinse
                thoroughly to make sure there’s no residue left behind.{" "}
                <strong>
                  Since it’s a powerful cleanser, use 1 - 2 times a week.{" "}
                </strong>
                Use the free scalp massager included on this package for extra
                build-up removal and refreshing scalp massage.
              </p>
            </div>
            <div className="w-col w-col-6">
              <h4 className="step-heading">Step 2:</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src={Routine2}
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
                Pour a dollop of Coconut/Avocado Oil conditioner on to your
                palm. Massage gently on to the length of damp hair till the
                ends. Avoid application on the roots, unless you have dry scalp
                &amp; hair.{" "}
                <strong>
                  Rinse it thoroughly with plenty of cold water to seal the
                  cuticles.{" "}
                </strong>
                Frequency of use depends on hair type/concern - please follow
                the instructions on packaging for better results.
              </p>
            </div>
          </div>
        </div>
      )}

      {id === "apple-cider-vinegar-face-wash" && (
        <>
          <div className="container">
            <div className="w-container">
              <div className="product-list-content w-row">
                <div className="w-col w-col-28">
                  <ALink href="#" className="w-inline-block">
                    <Image src={ACVFW} height={697} width={697} />
                  </ALink>
                </div>
                <div className="w-col w-col-8">
                  <div className="rich-text-block w-richtext pt-0">
                    <ul
                      role="list"
                      className="features-right-2 wrap w-list-unstyled mt-0"
                    >
                      <h2 className="heading p-0 mt-0">Foaming Face Wash</h2>

                      <li className="features-block-two-2">
                        <p class="stepbstep-product-txt">
                          <strong>Step 1</strong>
                          <br />
                          Splash plain water on your face and neck to prepare
                          your skin.
                          <br />
                          <br />
                          <strong>
                            Step 2<br />‍
                          </strong>
                          Pump a small amount of face wash onto the brush. Use
                          it in a circular motion all over your face to remove
                          dirt.
                          <br />
                          <br />
                          <strong>Step 3</strong>
                          <br />
                          Massage for 2 minutes then rinse thoroughly with plain
                          water. Pat dry and moisturize.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="product-list-content w-row">
                <div className="w-col w-col-28">
                  <ALink href="#" className="w-inline-block">
                    <Image src={ACVFM} height={697} width={697} />
                  </ALink>
                </div>
                <div className="w-col w-col-8">
                  <div className="rich-text-block w-richtext pt-4">
                    <ul
                      role="list"
                      className="features-right-2 wrap w-list-unstyled mt-0"
                    >
                      <h2 className="heading p-0 mt-0">Face Moisturizer</h2>

                      <li className="features-block-two-2">
                        <p class="stepbstep-product-txt">
                          *Buy Pack of 3 to use this on your regime.
                          <strong>
                            <br />
                            <br />
                            Step 1<br />
                          </strong>
                          Take a small amount of our apple cider vinegar face
                          moisturizer on your fingers. Dot it all over your
                          cleansed face and neck.
                          <strong>
                            <br />
                            <br />
                            Step 2<br />
                          </strong>
                          Massage using fingers with an upward stroking motion.
                          Go from inside of your face towards the hairline and
                          from neck to jaw and upwards.
                          <strong>
                            <br />
                            <br />
                            Step 3<br />
                          </strong>
                          Use it twice a day to keep your oily skin balanced and
                          hydrated.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {id === "apple-cider-vinegar-face-wash-set2" && (
        <>
          <div className="reasons-top w-container mb-0">
            <img src="/images/skincare.jpg" className="routine-img" />
          </div>
        </>
      )}

      {id === "aloe-vera-face-wash" && (
        <>
          <div className="reasons-top w-container mb-0">
            <img src="/images/home/how-to-use.jpeg" className="routine-img" />
          </div>
        </>
      )}

      {id === "anti-acne-face-serum" && (
        <>
          <div className="reasons-top w-container mb-0">
            <img src="/images/acne-routine.png" className="routine-img" />
          </div>
        </>
      )}

      {id === "vitamin-c-and-niacinamide-face-wash" && (
        <>
          <div className="container">
            <div className="w-container">
              <div className="product-list-content w-row">
                <div className="w-col w-col-28">
                  <ALink href="#" className="w-inline-block">
                    <Image src={VitCFw} height={697} width={697} />
                  </ALink>
                </div>
                <div className="w-col w-col-8">
                  <div className="rich-text-block w-richtext pt-0">
                    <ul
                      role="list"
                      className="features-right-2 wrap w-list-unstyled mt-0"
                    >
                      <h2 className="heading p-0 mt-0">Foaming Face Wash</h2>

                      <li className="features-block-two-2">
                        <p class="stepbstep-product-txt">
                          <strong>Step 1</strong>
                          <br />
                          Splash plain water on your face and neck to prepare
                          your skin.
                          <br />
                          <br />
                          <strong>
                            Step 2<br />‍
                          </strong>
                          Pump a small amount of face wash onto the brush. Use
                          it in a circular motion all over your face to remove
                          dirt.
                          <br />
                          <br />
                          <strong>Step 3</strong>
                          <br />
                          Massage for 2 minutes then rinse thoroughly with plain
                          water. Pat dry and moisturize.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="product-list-content w-row">
                <div className="w-col w-col-28">
                  <ALink href="#" className="w-inline-block">
                    <Image src={VitCSerum} height={697} width={697} />
                  </ALink>
                </div>
                <div className="w-col w-col-8">
                  <div className="rich-text-block w-richtext pt-4">
                    <ul
                      role="list"
                      className="features-right-2 wrap w-list-unstyled mt-0"
                    >
                      <h3 className="heading p-0 mt-0">Face Serum</h3>
                      <li className="features-block-two-2">
                        <p class="stepbstep-product-txt">
                          *Buy Pack of 3 to use this on your regime.
                          <strong>
                            <br />
                            <br />
                            Step 1<br />
                          </strong>
                          After cleansing face, dispense a pea sized amount on
                          your palm.
                          <strong>
                            <br />
                            <br />
                            Step 2<br />
                          </strong>
                          Smooth it on your face and neck. Dab & press, do not
                          vigorously rub.
                          <strong>
                            <br />
                            <br />
                            Step 3<br />
                          </strong>
                          Wait till the serum is fully absorbed into the skin.
                          No need to wash off.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {id === "rosemary-oil-shampoo-and-hair-conditioner-combo" && (
        <div className="product-list-content w-container">
          <div className="w-row">
            <div className="w-col w-col-6">
              <h4 className="step-heading">Shampoo</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src={RmaryRoutine1}
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
                Pour a generous dollop of shampoo onto your palm and massage it
                onto your scalp using only your fingertips. Rinse off the
                shampoo finishing off with cold water.
              </p>
            </div>
            <div className="w-col w-col-6">
              <h4 className="step-heading">Conditioner</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src={RmaryRoutine2}
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
                Pour a dollop of conditioner on to your palm. Massage gently on
                to the length of damp hair till the ends. Rinse it thoroughly
                with plenty of cold water to seal the cuticles.
              </p>
            </div>
          </div>
          <div className="w-row">
            <div className="w-col w-col-6">
              <h4 className="step-heading">Hair Oil</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src={RmaryRoutine3}
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
                Pump out generous amount of oil on your scalp. Gently massage
                onto scalp then work it through the length. Leave on for at
                least an hour before shampooing.
              </p>
            </div>
            <div className="w-col w-col-6">
              <h4 className="step-heading">Serum</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src={RmaryRoutine4}
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
                Spray on the affected area, making sure the scalp is clean &
                dry. Massage your scalp using your fingertips. Do not rinse and
                leave it overnight.
              </p>
            </div>
          </div>
        </div>
      )}

{id === "onion-combo-kit" && (
        <div className="product-list-content w-container">
          <div className="w-row">
            <div className="w-col w-col-6">
              <h4 className="step-heading">Hair Oil</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/onionKit/hairoil-use.png"
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
              Pump out generous amount of oil on your scalp. Gently massage onto scalp then work it through the 
              length. Leave on for at least an hour before shampooing.
              </p>
            </div>
            <div className="w-col w-col-6">
              <h4 className="step-heading">Shampoo</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/onionKit/Onion Shampoo-250ml.jpg"conditioner-use
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
              Pour a generous dollop of shampoo onto your palm and massage it onto your scalp using 
              only your fingertips. Rinse off the shampoo finishing off with cold water.
              </p>
            </div>
          </div>
          <div className="w-row">
            <div className="w-col w-col-6">
              <h4 className="step-heading">Conditioner</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/onionKit/conditioner-use.png"
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
              Pour a dollop of conditioner on to your palm. Massage gently on to the length of damp 
              hair till the ends. Rinse it thoroughly with plenty of cold water to seal the cuticles.
              </p>
            </div>
            <div className="w-col w-col-6">
              <h4 className="step-heading">Hair Mask</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/onionKit/mask-use.jpg"
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
              After shampooing, towel-dry your hair to remove excess water. Take an adequate amount of the Hair Mask and 
              apply it evenly from the roots to the tips. Gently massage the mask into your scalp and hair for thorough coverage 
              and absorption. After the recommended time(15-20mins), rinse your hair thoroughly with lukewarm water. Make sure all 
              traces of the mask are washed out.
              </p>
            </div>
          </div>
        </div>
      )}

{id === "onion-complete-hair-care-kit" && (
        <div className="product-list-content w-container">
          <div className="w-row">
            <div className="w-col w-col-6">
              <h4 className="step-heading">Hair Oil</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/onionKit/how-to-use_hair-oil-200ml.jpg"
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
              Pump out generous amount of oil on your scalp. Gently massage onto scalp then work it through the 
              length. Leave on for at least an hour before shampooing.
              </p>
            </div>
            <div className="w-col w-col-6">
              <h4 className="step-heading">Shampoo</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/onionKit/how-to-use_shampoo-300ml.jpg"conditioner-use
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
              Pour a generous dollop of shampoo onto your palm and massage it onto your scalp using 
              only your fingertips. Rinse off the shampoo finishing off with cold water.
              </p>
            </div>
          </div>
          <div className="w-row">
            <div className="w-col w-col-6">
              <h4 className="step-heading">Conditioner</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/onionKit/how-to-use_conditioner-300ml.jpg"
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
              Pour a dollop of conditioner on to your palm. Massage gently on to the length of damp 
              hair till the ends. Rinse it thoroughly with plenty of cold water to seal the cuticles.
              </p>
            </div>
            <div className="w-col w-col-6">
              <h4 className="step-heading">Hair Serum</h4>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/onionKit/how-to-use_serum.jpg"
                  height={300}
                  width={300}
                  className="image-routine"
                />
              </div>
              <p className="routine-p">
              Apply a small amount of Red Onion Black Seed Hair Serum to the mid-lengths and ends of damp, 
              towel-dried hair. Avoid the roots to prevent oiliness. This enhances hair strength, reduces breakage, 
              and makes hair manageable.
              </p>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default Routine;
