import React from "react";
import SlideToggle from "react-slide-toggle";
import ALink from "~/components/features/custom-link";
import { scrollWithOffset } from "~/utils/helper";

function FAQ({ faqs, data }) {
  const onButtonClick = () => {
    scrollWithOffset("product-details", 10, (ele) => {
      ele.click();
    });
  };

  return (
    <>
      {faqs?.length === 0 ? (
        <></>
      ) : (
        <div className="faq mb-10 mt-10 container1" id="faq">
          <div className="faq-title">Frequently Asked Questions</div>
          <div className="d-flex align-items-center flex-column">
            {faqs?.map((item) => {
              return (
                <div className="faq-accordion mb-2">
                  <Card
                    svgIcon={<FaqCheckmark size={18} />}
                    title={
                      <div dangerouslySetInnerHTML={{ __html: item.title }} />
                    }
                    noDisplayStyle
                    collapseEvent
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <ALink
        href="#"
        className="button-product-ingredients w-button"
        onClick={onButtonClick}
      >
        {data.buttonText}
      </ALink>
    </>
  );
}

export default FAQ;

function Card(props) {
  const {
    title,
    expanded = false,
    adClass,
    colorClass,
    iconClass,
    type = "normal",
    url,
    onLinkClick = () => {},
    id,
    hideDropDown,
    svgIcon,
    ...restProps
  } = props;

  return "normal" === type ? (
    <SlideToggle {...restProps} collapsed={expanded ? false : true}>
      {({ onToggle, setCollapsibleElement, toggleState }) => (
        <div
          className={`card ${adClass}`}
          style={{ backgroundColor: "#f7ffef", borderRadius: "20px" }}
        >
          <div
            id={id}
            className={`card-header  ${colorClass}`}
            onClick={onToggle}
          >
            <ALink
              onClick={onLinkClick}
              href="#"
              className={`toggle-button toggle-wrapper toggle-grid ${toggleState.toLowerCase()}`}
            >
              <div className="d-flex align-items-center">
                {svgIcon && <div className="svg-icon mr-2">{svgIcon}</div>}
                {title ? title : ""}
              </div>
              <div className="pr-5">
                {!hideDropDown && (
                  <i className="icon">
                    <DownAngle
                      size={12}
                      color="currentColor"
                      className="vertical-align-super"
                    />
                  </i>
                )}
              </div>
            </ALink>
          </div>

          <div ref={setCollapsibleElement}>
            <div className="card-body overflow-hidden">{props.children}</div>
          </div>
        </div>
      )}
    </SlideToggle>
  ) : "parse" === type ? (
    <SlideToggle collapsed={expanded ? false : true}>
      {({ onToggle, setCollapsibleElement, toggleState }) => (
        <>
          <ALink
            href={url ? url : "#"}
            content={title}
            className={`parse-content ${toggleState.toLowerCase()}`}
            onClick={(e) => {
              onToggle();
              if (onLinkClick) {
                onLinkClick();
              }
            }}
          ></ALink>

          <div ref={setCollapsibleElement} className="overflow-hidden">
            {props.children}
          </div>
        </>
      )}
    </SlideToggle>
  ) : (
    <SlideToggle collapsed={expanded ? false : true}>
      {({ onToggle, setCollapsibleElement, toggleState }) => (
        <>
          <ALink onClick={onLinkClick} href={url ? url : "#"}>
            {title}
            <span
              className={`toggle-btn ${toggleState.toLowerCase()}`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onToggle();
              }}
            >
              {!hideDropDown &&
                (["expanded", "expanding"].includes(
                  toggleState.toLowerCase()
                ) ? (
                  <DownAngle size={12} color="currentColor" />
                ) : (
                  <DownAngle
                    size={12}
                    color="currentColor"
                    className="icon-rotate-reverse"
                  />
                ))}
            </span>
          </ALink>

          <div ref={setCollapsibleElement} className="overflow-hidden">
            {props.children}
          </div>
        </>
      )}
    </SlideToggle>
  );
  return "";
}

export const DownAngle = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      className="vertical-align-super"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
      ></path>
    </svg>
  );
};

export const RightAngle = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      className="vertical-align-super"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      transform="rotate(180)"
    >
      <path
        fill={color}
        d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
      ></path>
    </svg>
  );
};

export const FaqCheckmark = () => {
  return (
    <svg
      class="icon icon-accordion color-foreground-text"
      aria-hidden="true"
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M18.5 1.5H1.5L1.5 18.5H18.5V1.5ZM1.5 0.5C0.947715 0.5 0.5 0.947715 0.5 1.5V18.5C0.5 19.0523 0.947715 19.5 1.5 19.5H18.5C19.0523 19.5 19.5 19.0523 19.5 18.5V1.5C19.5 0.947715 19.0523 0.5 18.5 0.5H1.5Z"
        fill-rule="evenodd"
      ></path>
      <path d="M14.9975 6.09084C15.201 6.27746 15.2147 6.59375 15.0281 6.79728L8.91631 13.4627C8.82231 13.5652 8.68987 13.6239 8.55079 13.6247C8.41172 13.6256 8.27857 13.5684 8.18335 13.4671L4.99513 10.0731C4.80606 9.87179 4.81596 9.55536 5.01723 9.3663C5.21849 9.17723 5.53492 9.18713 5.72399 9.3884L8.54335 12.3897L14.291 6.12145C14.4776 5.91791 14.7939 5.90421 14.9975 6.09084Z"></path>
    </svg>
  );
};
