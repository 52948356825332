import Image from "next/image";
import React from "react";
import ALink from "~/components/features/custom-link";
import Dandruff from "~/public/images/cta/dandruff.png";
import Hairfall from "~/public/images/cta/hairfall.png";
import DullHair from "~/public/images/cta/dull-hair.png";
import Splitends from "~/public/images/cta/splitends.png";
import DryScalp from "~/public/images/cta/dry-scalp.png";
import HairBreak from "~/public/images/cta/hair-break.png";
import sc1 from "~/public/images/sc1.png";
import sc2 from "~/public/images/sc2.png";
import sc3 from "~/public/images/sc3.png";
import sc4 from "~/public/images/sc4.png";
import sc5 from "~/public/images/sc5.png";
import sc6 from "~/public/images/sc6.png";
import fw1 from "~/public/images/subpages/rad1.png";
import fw2 from "~/public/images/subpages/rad2.png";
import fw3 from "~/public/images/subpages/rad3.png";
import fw4 from "~/public/images/subpages/rad4.png";
import fw5 from "~/public/images/subpages/rad5.png";
import fw6 from "~/public/images/subpages/rad6.png";
import sebum from "~/public/images/sebum.png";
import hormones from "~/public/images/hormones.png";
import pores from "~/public/images/pores.png";
import bacteria from "~/public/images/bacteria.png";
import genetics from "~/public/images/genetics.png";
import thinHair from "~/public/images/thin-hair.png";
import badscalp from "~/public/images/bad-scalp.png";
import vitc5 from "~/public/images/cta/vitc-dryness.png";
import vitc1 from "~/public/images/cta/vitc-dull-skin.png";
import vitc3 from "~/public/images/cta/vitc-finelines.png";
import vitc6 from "~/public/images/cta/vitc-patchy.png";
import vitc4 from "~/public/images/cta/vitc-pore-congestion.png";
import vitc2 from "~/public/images/cta/vitc-uneven-skintone.png";

import { scrollWithOffset } from "~/utils/helper";

const ProductDetails = (props) => {
  const { data, id } = props;

  const onButtonClick = () => {
    scrollWithOffset("product-details", 10, (ele) => {
      ele.click();
    });
  };

  return (
    <>
      <div className="trivia-box" id="benefits">
        <h4 className="">{data.heading}</h4>
        <div className="text-block wrap">{data.description}</div>
      </div>
      {id === "apple-cider-vinegar-shampoo-set" && (
        <div className="rec-section">
          <p className="text-block mb-2 text-recommended">
            RECOMMENDED BY COSMETOLOGISTS AND BEAUTY EXPERTS
          </p>
          <h3 className="better-you">
            The Apple Cider Vinegar Shampoo Conditioner..
            <br />
            Better Hair, Better Scalp, Better Hair Days!
          </h3>
          <p className="text-block text-details">
            Whether you are{" "}
            <strong>looking to switch to natural hair care solutions</strong>{" "}
            OR&nbsp;
            <br />
            If you answer yes to any of these below...
            <br />
            You need our ACV Shampoo Duo!
            <br />
            <br />
            <strong>DO YOU HAVE...</strong>
          </p>
          <div className="gp-container advantages w-container-1">
            <div className="advantage advantage-1">
              <Image src={Dandruff} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dandruff?</h5>
              <div className="text-block">
                Flakes, itchy and irritated scalp.
                <br />
                <br />
                Apple Cider Vinegar (ACV) contains acetic acid that alters the
                scalp's pH, making it less hospitable for the dandruff-causing
                fungus Malassezia, reducing flakiness and itching.
              </div>
            </div>
            <div className="advantage">
              <Image src={Hairfall} height={150} width={150} />
              <h5 className="mb-2 mt-2">Hair Fall?</h5>
              <div className="text-block">
                Excessive hair loss, thinning, and weak strands.
                <br />
                <br />
                Apple Cider Vinegar (ACV) may help reduce hair fall by
                strengthening hair follicles and improving their overall health,
                which can result in reduced breakage and shedding.
              </div>
            </div>
            <div className="advantage">
              <Image src={DullHair} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dull Hair?</h5>
              <div className="text-block">
                Lack of shine, lifeless and flat appearance.
                <br />
                <br />
                Apple Cider Vinegar (ACV) restores hair's natural shine by
                balancing pH levels, smoothing the cuticle, and removing mineral
                buildup. This enhances light reflection, resulting in hair that
                appears healthier and less dull.
              </div>
            </div>
          </div>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={Splitends} height={150} width={150} />
              <h5 className="mb-2 mt-2">Split Ends?</h5>
              <div className="text-block">
                Frayed ends, damaged, and weakened hair tips. <br />
                <br />
                Apple Cider Vinegar (ACV) mitigates split ends by fortifying and
                hydrating the hair shaft, reducing brittleness. Its acidity
                helps seal cuticles, preventing further splitting and enhancing
                hair strength.
              </div>
            </div>
            <div className="advantage">
              <Image src={DryScalp} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dry and Itchy Scalp?</h5>
              <div className="text-block">
                Dryness, itching, and discomfort on the scalp.
                <br />
                <br />
                Apple Cider Vinegar (ACV) helps a dry and itchy scalp by
                restoring its pH balance and providing relief from inflammation.
                Its acetic acid exfoliates dead skin cells and soothes
                itchiness.
              </div>
            </div>
            <div className="advantage">
              <Image src={HairBreak} height={150} width={150} />
              <h5 className="mb-2 mt-2">Hair Breakage?</h5>
              <div className="text-block">
                Weak and brittle hair that breaks easily.
                <br />
                <br />
                Apple Cider Vinegar (ACV) helps with hair breakage by
                strengthening hair strands. Its natural acidity smoothes the
                hair's cuticles, reducing friction and preventing breakage while
                improving overall hair health.
              </div>
            </div>
          </div>
          <ALink
            href="#"
            className="button-hair-issues"
            onClick={onButtonClick}
          >
            Mend My Hair & Scalp Issues Now →
          </ALink>
        </div>
      )}
      {id === "apple-cider-vinegar-shampoo-set-2" && (
        <div className="rec-section">
          <p className="text-block mb-2 text-recommended">
            RECOMMENDED BY COSMETOLOGISTS AND BEAUTY EXPERTS
          </p>
          <h3 className="better-you">
            The Apple Cider Vinegar Shampoo Conditioner..
            <br />
            Better Hair, Better Scalp, Better Hair Days!
          </h3>
          <p className="text-block text-details">
            Whether you are{" "}
            <strong>looking to switch to natural hair care solutions</strong>{" "}
            OR&nbsp;
            <br />
            If you answer yes to any of these below...
            <br />
            You need our ACV Shampoo Duo!
            <br />
            <br />
            <strong>DO YOU HAVE...</strong>
          </p>
          <div className="gp-container advantages w-container-1">
            <div className="advantage advantage-1">
              <Image src={Dandruff} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dandruff?</h5>
              <div className="text-block">
                Flakes, itchy and irritated scalp.
                <br />
                <br />
                Apple Cider Vinegar (ACV) contains acetic acid that alters the
                scalp's pH, making it less hospitable for the dandruff-causing
                fungus Malassezia, reducing flakiness and itching.
              </div>
            </div>
            <div className="advantage">
              <Image src={Hairfall} height={150} width={150} />
              <h5 className="mb-2 mt-2">Hair Fall?</h5>
              <div className="text-block">
                Excessive hair loss, thinning, and weak strands.
                <br />
                <br />
                Apple Cider Vinegar (ACV) may help reduce hair fall by
                strengthening hair follicles and improving their overall health,
                which can result in reduced breakage and shedding.
              </div>
            </div>
            <div className="advantage">
              <Image src={DullHair} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dull Hair?</h5>
              <div className="text-block">
                Lack of shine, lifeless and flat appearance.
                <br />
                <br />
                Apple Cider Vinegar (ACV) restores hair's natural shine by
                balancing pH levels, smoothing the cuticle, and removing mineral
                buildup. This enhances light reflection, resulting in hair that
                appears healthier and less dull.
              </div>
            </div>
          </div>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={Splitends} height={150} width={150} />
              <h5 className="mb-2 mt-2">Split Ends?</h5>
              <div className="text-block">
                Frayed ends, damaged, and weakened hair tips. <br />
                <br />
                Apple Cider Vinegar (ACV) mitigates split ends by fortifying and
                hydrating the hair shaft, reducing brittleness. Its acidity
                helps seal cuticles, preventing further splitting and enhancing
                hair strength.
              </div>
            </div>
            <div className="advantage">
              <Image src={DryScalp} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dry and Itchy Scalp?</h5>
              <div className="text-block">
                Dryness, itching, and discomfort on the scalp.
                <br />
                <br />
                Apple Cider Vinegar (ACV) helps a dry and itchy scalp by
                restoring its pH balance and providing relief from inflammation.
                Its acetic acid exfoliates dead skin cells and soothes
                itchiness.
              </div>
            </div>
            <div className="advantage">
              <Image src={HairBreak} height={150} width={150} />
              <h5 className="mb-2 mt-2">Hair Breakage?</h5>
              <div className="text-block">
                Weak and brittle hair that breaks easily.
                <br />
                <br />
                Apple Cider Vinegar (ACV) helps with hair breakage by
                strengthening hair strands. Its natural acidity smoothes the
                hair's cuticles, reducing friction and preventing breakage while
                improving overall hair health.
              </div>
            </div>
          </div>
          <ALink
            href="#"
            className="button-hair-issues"
            onClick={onButtonClick}
          >
            Mend My Hair & Scalp Issues Now →
          </ALink>
        </div>
      )}

      {id === "apple-cider-vinegar-face-wash" && (
        <div className="rec-section">
          <p className="text-block mb-2 text-recommended">
            RECOMMENDED BY DERMATOLOGISTS AND BEAUTY EXPERTS
          </p>
          <h3 className="better-you">
            The ACV Face Wash..
            <br />
            Better Skin, Better Glow, Better Skin Days!
          </h3>
          <p className="text-block text-details">
            Whether you are{" "}
            <strong>looking to switch to natural skincare solutions </strong>{" "}
            OR&nbsp;
            <br />
            If you answer yes to any of these below...
            <br />
            You need our ACV Face Wash!
            <br />
            <br />
            <strong>DO YOU HAVE...</strong>
          </p>
          <div className="gp-container advantages w-container-1">
            <div className="advantage advantage-1">
              <Image src={sc1} height={150} width={150} />
              <h5 className="mb-2 mt-2">Acne</h5>
              <div className="text-block">
                Inflammatory skin condition causing pimples.
                <br />
                <br />
                ACV Face Wash <strong>balances pH, reducing acne </strong> and
                promoting clear, healthy skin.
              </div>
            </div>
            <div className="advantage">
              <Image src={sc2} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dryness</h5>
              <div className="text-block">
                Lack of moisture causing rough or flaky skin.
                <br />
                <br />
                ACV Face Wash{" "}
                <strong>
                  hydrates, restoring moisture, and maintains optimal pH
                </strong>{" "}
                for soft, supple skin.
              </div>
            </div>
            <div className="advantage">
              <Image src={sc3} height={150} width={150} />
              <h5 className="mb-2 mt-2">Uneven Skin Tone</h5>
              <div className="text-block">
                Irregular pigmentation, resulting in patches.
                <br />
                <br />
                ACV Face Wash{" "}
                <strong>evens skin tone, brightening complexion</strong> by
                restoring pH balance.
              </div>
            </div>
          </div>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={sc4} height={150} width={150} />
              <h5 className="mb-2 mt-2">Sensitivity</h5>
              <div className="text-block">
                Easily irritated skin, prone to redness.
                <br />
                <br />
                ACV Face Wash{" "}
                <strong>
                  soothes sensitive skin, maintaining a balanced pH
                </strong>{" "}
                for calm and comfort.
              </div>
            </div>
            <div className="advantage">
              <Image src={sc5} height={150} width={150} />
              <h5 className="mb-2 mt-2">Excess Oil</h5>
              <div className="text-block">
                Overproduction of skin's natural oils.
                <br />
                <br />
                ACV Face Wash{" "}
                <strong>
                  regulates oil balance, minimizing excess oil
                </strong>{" "}
                production and restoring pH.
              </div>
            </div>
            <div className="advantage">
              <Image src={sc6} height={150} width={150} />
              <h5 className="mb-2 mt-2">Aging Signs</h5>
              <div className="text-block">
                Fine lines, wrinkles, and loss of elasticity.
                <br />
                <br />
                ACV Face Wash{" "}
                <strong>combats signs of aging by restoring pH</strong> and
                promoting a youthful appearance.
              </div>
            </div>
          </div>
          <ALink
            href="#"
            className="button-hair-issues"
            onClick={onButtonClick}
          >
            Mend My Facial Skin Issues Now →
          </ALink>
        </div>
      )}

      {id === "apple-cider-vinegar-face-wash-set2" && (
        <div className="rec-section">
          <p className="text-block mb-2 text-recommended">
            RECOMMENDED BY DERMATOLOGISTS AND BEAUTY EXPERTS
          </p>
          <h3 className="better-you">
            The ACV Skincare..
            <br />
            Better Skin, Better Glow, Better Skin Days!
          </h3>
          <p className="text-block text-details">
            Whether you are{" "}
            <strong>looking to switch to natural skincare solutions </strong>{" "}
            OR&nbsp;
            <br />
            If you answer yes to any of these below...
            <br />
            You need our ACV Skincare!
            <br />
            <br />
            <strong>DO YOU HAVE...</strong>
          </p>
          <div className="gp-container advantages w-container-1">
            <div className="advantage advantage-1">
              <Image src={sc1} height={150} width={150} />
              <h5 className="mb-2 mt-2">Acne</h5>
              <div className="text-block">
                Inflammatory skin condition causing pimples.
                <br />
                <br />
                ACV Skincare <strong>balances pH, reducing acne </strong> and
                promoting clear, healthy skin.
              </div>
            </div>
            <div className="advantage">
              <Image src={sc2} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dryness</h5>
              <div className="text-block">
                Lack of moisture causing rough or flaky skin.
                <br />
                <br />
                ACV Skincare{" "}
                <strong>
                  hydrates, restoring moisture, and maintains optimal pH
                </strong>{" "}
                for soft, supple skin.
              </div>
            </div>
            <div className="advantage">
              <Image src={sc3} height={150} width={150} />
              <h5 className="mb-2 mt-2">Uneven Skin Tone</h5>
              <div className="text-block">
                Irregular pigmentation, resulting in patches.
                <br />
                <br />
                ACV Skincare{" "}
                <strong>evens skin tone, brightening complexion</strong> by
                restoring pH balance.
              </div>
            </div>
          </div>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={sc4} height={150} width={150} />
              <h5 className="mb-2 mt-2">Sensitivity</h5>
              <div className="text-block">
                Easily irritated skin, prone to redness.
                <br />
                <br />
                ACV Skincare{" "}
                <strong>
                  soothes sensitive skin, maintaining a balanced pH
                </strong>{" "}
                for calm and comfort.
              </div>
            </div>
            <div className="advantage">
              <Image src={sc5} height={150} width={150} />
              <h5 className="mb-2 mt-2">Excess Oil</h5>
              <div className="text-block">
                Overproduction of skin's natural oils.
                <br />
                <br />
                ACV Skincare{" "}
                <strong>
                  regulates oil balance, minimizing excess oil
                </strong>{" "}
                production and restoring pH.
              </div>
            </div>
            <div className="advantage">
              <Image src={sc6} height={150} width={150} />
              <h5 className="mb-2 mt-2">Aging Signs</h5>
              <div className="text-block">
                Fine lines, wrinkles, and loss of elasticity.
                <br />
                <br />
                ACV Skincare{" "}
                <strong>combats signs of aging by restoring pH</strong> and
                promoting a youthful appearance.
              </div>
            </div>
          </div>
          <ALink
            href="#"
            className="button-hair-issues"
            onClick={onButtonClick}
          >
            Mend My Facial Skin Issues Now →
          </ALink>
        </div>
      )}

      {id === "aloe-vera-face-wash" && (
        <div className="rec-section">
          <p className="text-block mb-2 text-recommended">
            RECOMMENDED BY DERMATOLOGISTS AND BEAUTY EXPERTS
          </p>
          <h3 className="better-you">
            The Aloe Vera Face Wash..
            <br />
            Better Skin, Better Glow, Better Skin Days!
          </h3>
          <p className="text-block text-details">
            Whether you are{" "}
            <strong>looking to switch to natural skincare solutions </strong>{" "}
            OR&nbsp;
            <br />
            If you answer yes to any of these below...
            <br />
            You need our Aloe Vera Face Wash!
            <br />
            <br />
            <strong>DO YOU HAVE...</strong>
          </p>
          <div className="gp-container advantages w-container-1">
            <div className="advantage advantage-1">
              <Image src={fw1} height={150} width={150} />
              <h5 className="mb-2 mt-2"> Dryness and Dehydration?</h5>
              <div className="text-block">
                Aloe Vera's <strong>exceptional moisturizing properties</strong>{" "}
                make it effective in combating dry and dehydrated skin,
                promoting optimal hydration.
              </div>
            </div>
            <div className="advantage">
              <Image src={fw2} height={150} width={150} />
              <h5 className="mb-2 mt-2">Sunburn and Irritation?</h5>
              <div className="text-block">
                The soothing and cooling nature of Aloe Vera makes it a go-to
                remedy for sunburns and skin irritations,{" "}
                <strong>
                  providing relief and aiding in the healing process
                </strong>
                .
              </div>
            </div>
            <div className="advantage">
              <Image src={fw3} height={150} width={150} />
              <h5 className="mb-2 mt-2">Acne and Blemishes?</h5>
              <div className="text-block">
                Aloe Vera's anti-inflammatory and antibacterial qualities can
                help{" "}
                <strong>
                  reduce inflammation and redness associated with acne,
                </strong>
                contributing to clearer and smoother skin.
              </div>
            </div>
          </div>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={fw4} height={150} width={150} />
              <h5 className="mb-2 mt-2">Sensitivity?</h5>
              <div className="text-block">
                For individuals with sensitive skin, Aloe Vera's gentle nature
                can help{" "}
                <strong>calm and alleviate redness or discomfort</strong>,
                making it suitable for those prone to skin sensitivity.
              </div>
            </div>
            <div className="advantage">
              <Image src={fw5} height={150} width={150} />
              <h5 className="mb-2 mt-2">Uneven Skin Tone</h5>
              <div className="text-block">
                Aloe Vera's skin-regenerating properties may help even out skin
                tone by <strong>promoting the renewal of skin cells</strong>,
                resulting in a more balanced and radiant complexion.
              </div>
            </div>
            <div className="advantage">
              <Image src={fw6} height={150} width={150} />
              <h5 className="mb-2 mt-2">Aging Signs?</h5>
              <div className="text-block">
                Aloe Vera contains{" "}
                <strong>
                  antioxidants that may assist in minimizing the appearance of
                  fine lines
                </strong>{" "}
                and wrinkles, contributing to a more youthful and revitalized
                complexion.
              </div>
            </div>
          </div>
          <ALink
            href="#"
            className="button-hair-issues"
            onClick={onButtonClick}
          >
            I need Aloe Vera Face Wash →
          </ALink>
        </div>
      )}

      {id === "vitamin-c-and-niacinamide-face-wash" && (
        <div className="rec-section">
          <p className="text-block mb-2 text-recommended">
            RECOMMENDED BY DERMATOLOGISTS AND BEAUTY EXPERTS
          </p>
          <h3 className="better-you">
            The Vitamin C & Niacinamide Face Wash..
            <br />
            Better Skin, Better Radiance, Rejuvenated Skin Days!
          </h3>
          <p className="text-block text-details">
            Whether you are{" "}
            <strong>looking to switch to natural skincare solutions </strong>{" "}
            OR&nbsp;
            <br />
            If you answer yes to any of these below...
            <br />
            You need our Vitamin C & Niacinamide Face Wash!
            <br />
            <br />
            <strong>DO YOU HAVE...</strong>
          </p>
          <div className="gp-container advantages w-container-1">
            <div className="advantage advantage-1">
              <Image src={vitc1} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dull Skin?</h5>
              <div className="text-block">
                Wow Vitamin C Foaming Face Wash with active Vitamin C and citrus
                oils <strong>revives dull skin, removing dead cells</strong> for
                a brighter complexion, unveiling a radiant and refreshed
                appearance.
              </div>
            </div>
            <div className="advantage">
              <Image src={vitc2} height={150} width={150} />
              <h5 className="mb-2 mt-2">Uneven Skin Tone?</h5>
              <div className="text-block">
                Infused with liquorice and mulberry extracts, this face wash
                inhibits excess melanin production,{" "}
                <strong>promoting an even skin tone</strong>, diminishing dark
                spots, and revealing a luminous complexion.
              </div>
            </div>
            <div className="advantage">
              <Image src={vitc3} height={150} width={150} />
              <h5 className="mb-2 mt-2">Fine Lines?</h5>
              <div className="text-block">
                Packed with powerful antioxidants and Vitamin C, the face wash
                <strong>
                  fights signs of aging, reducing the appearance of fine lines
                </strong>
                , leaving skin smoother and more youthful after every use.
              </div>
            </div>
          </div>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={vitc4} height={150} width={150} />
              <h5 className="mb-2 mt-2">Pore Congestion?</h5>
              <div className="text-block">
                The built-in brush ensures{" "}
                <strong>deep cleansing, clearing away impurities</strong> and
                excess sebum, minimizing pore congestion. Enjoy fresh, clear
                skin and a refined texture with every wash.
              </div>
            </div>
            <div className="advantage">
              <Image src={vitc5} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dryness?</h5>
              <div className="text-block">
                Hyaluronic acid in the formula{" "}
                <strong>keeps skin soft, supple, and moisturized.</strong>{" "}
                Experience the nourishing benefits as the face wash hydrates,
                leaving your skin feeling refreshed and rejuvenated.
              </div>
            </div>
            <div className="advantage">
              <Image src={vitc6} height={150} width={150} />
              <h5 className="mb-2 mt-2">Patchy Complexion?</h5>
              <div className="text-block">
                Niacinamide, along with Vitamin C and botanical extracts,
                <strong>
                  addresses patchy complexion, promoting a more even skin
                  texture.
                </strong>
                Uncover smoother, healthier-looking skin with regular use of
                this face wash.
              </div>
            </div>
          </div>
          <ALink
            href="#"
            className="button-hair-issues"
            onClick={onButtonClick}
          >
            Mend my Facial Skin Issues Now →
          </ALink>
        </div>
      )}
      {id === "anti-acne-face-serum" && (
        <div className="rec-section">
          <p className="text-block mb-2 text-recommended">
            RECOMMENDED BY DERMATOLOGISTS AND BEAUTY EXPERTS
          </p>
          <h3 className="better-you">
            The Anti Acne Face Serum..
            <br />
            Flawless Radiance, Every Drop – Embrace Your Best Skin!
          </h3>
          <p className="text-block text-details">
            Whether you are{" "}
            <strong>looking to switch to natural acne care solutions </strong>{" "}
            OR&nbsp;
            <br />
            If you answer yes to any of these below...
            <br />
            You need our Anti Acne Face Serum!
            <br />
            <br />
            <strong>DO YOU HAVE ACNE CAUSED BY...</strong>
          </p>
          <div className="gp-container advantages w-container-1">
            <div className="advantage advantage-1">
              <Image src={sebum} height={150} width={150} />
              <h5 className="mb-2 mt-2">Excess Sebum Production?</h5>
              <div className="text-block">
                Overproduction of oil clogs pores, creating an environment for
                acne-causing bacteria to thrive and lead to breakouts.
                <br />
                <br />
                Our serum <strong>regulates sebum production</strong>,
                preventing pore-clogging and{" "}
                <strong>minimizing the risk of bacterial growth</strong> that
                leads to breakouts.
              </div>
            </div>
            <div className="advantage">
              <Image src={hormones} height={150} width={150} />
              <h5 className="mb-2 mt-2">Hormonal Changes?</h5>
              <div className="text-block">
                Hormonal fluctuations, especially during puberty or
                menstruation, can trigger increased oil production, contributing
                to acne development.
                <br />
                <br />
                Formulated to address hormonal imbalances, our serum{" "}
                <strong>helps control oil production</strong>, reducing the
                impact of hormonal fluctuations on acne development.
              </div>
            </div>
            <div className="advantage">
              <Image src={pores} height={150} width={150} />
              <h5 className="mb-2 mt-2">Clogged Pores?</h5>
              <div className="text-block">
                Accumulation of dead skin cells and debris in pores can result
                in blockages, fostering the growth of acne-causing bacteria.
                <br />
                <br />
                With a focus on unclogging pores, our serum{" "}
                <strong>promotes gentle exfoliation</strong>, preventing the
                accumulation of dead skin cells and debris that contribute to
                acne.
              </div>
            </div>
          </div>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={bacteria} height={150} width={150} />
              <h5 className="mb-2 mt-2">Bacterial Infection?</h5>
              <div className="text-block">
                Propionibacterium acnes, a bacteria found on the skin, can
                multiply and contribute to inflammation, leading to acne.
                <br />
                <br />
                Targeting acne-causing bacteria, our serum{" "}
                <strong>inhibits the growth of Propionibacterium acnes</strong>,
                minimizing the risk of bacterial infection and subsequent
                inflammation.
              </div>
            </div>
            <div className="advantage">
              <Image src={sc5} height={150} width={150} />
              <h5 className="mb-2 mt-2">Inflammation?</h5>
              <div className="text-block">
                Irritation and inflammation, often exacerbated by squeezing or
                picking at blemishes, can worsen existing acne or cause new
                breakouts.
                <br />
                <br />
                Designed to soothe inflammation, our serum{" "}
                <strong>helps calm irritated skin</strong>, reducing the
                likelihood of exacerbating existing acne or triggering new
                breakouts.
              </div>
            </div>
            <div className="advantage">
              <Image src={genetics} height={150} width={150} />
              <h5 className="mb-2 mt-2">Genetics?</h5>
              <div className="text-block">
                Genetic factors play a role in an individual's susceptibility to
                acne, with a family history of acne increasing the likelihood of
                its occurrence.
                <br />
                <br />
                While genetic factors play a role in acne susceptibility, our
                serum aids in maintaining a healthy skin balance, contributing
                to overall clearer and healthier skin.
              </div>
            </div>
          </div>
          <ALink
            href="#"
            className="button-hair-issues"
            onClick={onButtonClick}
          >
            Mend my Acne Issues Now →
          </ALink>
        </div>
      )}

      {id === "rosemary-oil-shampoo-and-hair-conditioner-combo" && (
        <div className="rec-section">
          <p className="text-block mb-2 text-recommended">
            RECOMMENDED BY COSMETOLOGISTS AND BEAUTY EXPERTS
          </p>
          <h3 className="better-you">
          The Rosemary Oil and Shampoo Duo gives you..
            <br />
            Better Hair, Better Scalp, Better Hair Days!
          </h3>
          <p className="text-block text-details">
            Whether you are{" "}
            <strong>looking to switch to natural hair care solutions</strong>{" "}
            OR&nbsp;
            <br />
            If you answer yes to any of these below...
            <br />
            You need our Rosemary Oil and Shampoo Duo!
            <br />
            <br />
            <strong>DO YOU HAVE...</strong>
          </p>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={Hairfall} height={150} width={150} />
              <h5 className="mb-2 mt-2">Hair Loss?</h5>
              <div className="text-block">
                The activated naturals combo of rosemary and biotin strengthens
                roots and reduces hair fall.
              </div>
            </div>
            <div className="advantage">
              <Image src={thinHair} height={150} width={150} />
              <h5 className="mb-2 mt-2">Thin and Weak Hair?</h5>
              <div className="text-block">
                Biotin forms a protective layer, minimizing breakage and split
                ends, resulting in thicker, resilient strands.
              </div>
            </div>
            <div className="advantage">
              <Image src={DryScalp} height={150} width={150} />
              <h5 className="mb-2 mt-2">Itchy Scalp?</h5>
              <div className="text-block">
                Rosemary soothes the scalp, providing relief from itchiness and
                promoting a healthier environment for hair growth.
              </div>
            </div>
          </div>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={DullHair} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dull and Lackluster Hair?</h5>
              <div className="text-block">
                Antioxidant-rich rosemary oil and moringa oil add gloss and
                shine, transforming dull hair into a lustrous mane.
              </div>
            </div>

            <div className="advantage">
              <Image src={HairBreak} height={150} width={150} />
              <h5 className="mb-2 mt-2">Brittle Hair?</h5>
              <div className="text-block">
                Biotin's protective properties fortify strands, reducing
                brittleness and improving overall hair strength.
              </div>
            </div>
            <div className="advantage">
              <Image src={badscalp} height={150} width={150} />
              <h5 className="mb-2 mt-2">Unhealthy Scalp?</h5>
              <div className="text-block">
                The natural ingredients, including rosemary and hibiscus
                extracts, moisturize and strengthen the scalp, addressing issues
                related to scalp health and encouraging new hair follicles.
              </div>
            </div>
          </div>
          <ALink
            href="#"
            className="button-hair-issues"
            onClick={onButtonClick}
          >
            End My Hair & Scalp Issues Now →
          </ALink>
        </div>
      )}

      {id === "onion-combo-kit" && (
        <div className="rec-section">
          <p className="text-block mb-2 text-recommended">
            RECOMMENDED BY COSMETOLOGISTS AND BEAUTY EXPERTS
          </p>
          <h3 className="better-you">
          Red Onion Black Seed Oil Hair Care..
            <br />
            Vibrant Hair, Renewed Scalp, Every Day is a Hair Wonderland!
          </h3>
          <p className="text-block text-details">
            Whether you are{" "}
            <strong>looking to switch to natural hair care solutions</strong>{" "}
            OR&nbsp;
            <br />
            If you answer yes to any of these below...
            <br />
            You need our Red Onion Black Seed Oil Kit!
            <br />
            <br />
            <strong>DO YOU HAVE...</strong>
          </p>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={Hairfall} height={150} width={150} />
              <h5 className="mb-2 mt-2">Hair Loss?</h5>
              <div className="text-block">
              Red Onion Seed Oil stimulates hair growth, strengthens hair follicles, and controls hair fall, 
              reducing the impact of hair loss.
              </div>
            </div>
            <div className="advantage">
              <Image src={thinHair} height={150} width={150} />
              <h5 className="mb-2 mt-2">Thin and Weak Hair?</h5>
              <div className="text-block">
              Black Seed Oil and Red Onion Seed Oil are rich in nutrients that nourish the hair, promoting thickness, 
              strength, and overall hair health.
              </div>
            </div>
            <div className="advantage">
              <Image src={DryScalp} height={150} width={150} />
              <h5 className="mb-2 mt-2">Itchy Scalp?</h5>
              <div className="text-block">
              The combination of Red Onion Seed Oil and Black Seed Oil has anti-inflammatory and anti-microbial properties, 
              helping to soothe and alleviate itchiness on the scalp.
              </div>
            </div>
          </div>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={DullHair} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dull and Lackluster Hair?</h5>
              <div className="text-block">
              Red Onion Seed Oil and Black Seed Oil contribute to the restoration of natural shine, 
              making hair vibrant and lustrous.
              </div>
            </div>

            <div className="advantage">
              <Image src={HairBreak} height={150} width={150} />
              <h5 className="mb-2 mt-2">Brittle Hair?</h5>
              <div className="text-block">
              Ingredients like Red Onion Seed Oil and Castor Oil in the kit help in strengthening the hair strands, 
              reducing brittleness and preventing breakage.
              </div>
            </div>
            <div className="advantage">
              <Image src={badscalp} height={150} width={150} />
              <h5 className="mb-2 mt-2">Unhealthy Scalp?</h5>
              <div className="text-block">
              Red Onion Seed Oil promotes scalp health by improving circulation, cleansing away impurities, and creating an environment conducive to healthy hair growth, 
              addressing the root cause of an unhealthy scalp.
              </div>
            </div>
          </div>
          <ALink
            href="#"
            className="button-hair-issues"
            onClick={onButtonClick}
          >
            End My Hair & Scalp Issues Now →
          </ALink>
        </div>
      )}

{id === "onion-complete-hair-care-kit" && (
        <div className="rec-section">
          <p className="text-block mb-2 text-recommended">
            RECOMMENDED BY COSMETOLOGISTS AND BEAUTY EXPERTS
          </p>
          <h3 className="better-you">
          <strong>The Onion Hair Care Kit..</strong>
            <br />
            <strong>Better Hair, Better Scalp, Better Hair Days!</strong>
          </h3>
          <p className="text-block text-details">
            Whether you are{" "}
            Whether you are <strong>looking to switch to natural hair care solutions</strong>{" "}
            OR&nbsp;
            <br />
            If you answer yes to any of these below....
            <br />
            You need our <strong> Onion Complete Hair Care Kit!</strong>
            <br />
            <br />
            <strong>DO YOU HAVE...</strong>
          </p>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={Hairfall} height={150} width={150} />
              <h5 className="mb-2 mt-2">Hair Loss?</h5>
              <div className="text-block">
              The activated naturals combo of red onion seed oil extract, 
              black seed oil strengthens roots and reduces hair fall.
              </div>
            </div>
            <div className="advantage">
              <Image src={thinHair} height={150} width={150} />
              <h5 className="mb-2 mt-2">Thin and Weak Hair?</h5>
              <div className="text-block">
              Onion hair care kit minimizes breakage and split ends, resulting in thicker, resilient strands.
              </div>
            </div>
            <div className="advantage">
              <Image src={DryScalp} height={150} width={150} />
              <h5 className="mb-2 mt-2">Itchy Scalp?</h5>
              <div className="text-block">
              The natural ingredients, including red onion seed oil extract, black seed oil moisturize and 
              strengthen the scalp, addressing issues related to scalp health and encouraging new hair growth.
              </div>
            </div>
          </div>
          <div className="gp-container advantages w-container-1">
            <div className="advantage">
              <Image src={DullHair} height={150} width={150} />
              <h5 className="mb-2 mt-2">Dull and Lackluster Hair?</h5>
              <div className="text-block">
              Red Onion Seed Oil and Black Seed Oil contribute to the restoration of natural shine, 
              making hair vibrant and lustrous.
              </div>
            </div>

            <div className="advantage">
              <Image src={HairBreak} height={150} width={150} />
              <h5 className="mb-2 mt-2">Brittle Hair?</h5>
              <div className="text-block">
              Ingredients like Red Onion Seed Oil and Castor Oil in the kit help in strengthening the hair strands, 
              reducing brittleness and preventing breakage.
              </div>
            </div>
            <div className="advantage">
              <Image src={badscalp} height={150} width={150} />
              <h5 className="mb-2 mt-2">Unhealthy Scalp?</h5>
              <div className="text-block">
              Red Onion Seed Oil promotes scalp health by improving circulation, cleansing away impurities, and creating an environment conducive to healthy hair growth, 
              addressing the root cause of an unhealthy scalp.
              </div>
            </div>
          </div>
          <ALink
            href="#"
            className="button-hair-issues"
            onClick={onButtonClick}
          >
            End My Hair & Scalp Issues Now →
          </ALink>
        </div>
      )}


      

    </>
  );
};

export default ProductDetails;
