import React from "react";
import { RatingStar } from "~/components/icons";

const CustomerReview = ({ id }) => {
  const ReviewData = [
    {
      name: "Prema S. ",
      says: "I love using this product because my skin in no longer very oily. It’s more balanced and stays clean and free of blackheads and pimples! Thank you Wow.",
      gender: "female",
    },
    {
      name: "Dipeeka B. ",
      says: "I am very happy I decided to order this face cleaner. My has blemishes and I just wanted over all healthy looking skin. After trying this skin cleaner... I refuse to use anything else. You will love this product trust me.",
      gender: "female",
    },
    {
      name: "Jyoti K.",
      says: "I am getting ready to reorder this face wash for a second time. I absolutely love it. It leave my face glowing and so soft. And I actually enjoy the smell. It has improved my skin greatly. Less dark spots, acne, and clogged pores.",
      gender: "female",
    },
    {
      name: "Akash D.",
      says: "I love this product, has my face feeling fresh & clean.Took the 3 bottle offer for my 2 sisters for them to try it out. Overall, it is a must-have on every skincare routine!",
      gender: "male",
    },
    {
      name: "Sunil M. ",
      says: "Love this face wash. It is also good for eliminating acne. I have 2 on my cheeks. They are slowly diminishing and getting smaller everyday.",
      gender: "male",
    },
    {
      name: "Vineeta P.",
      says: "This product is great overall, specially the facial brush that gives you a nice deep cleaning. Works good to keep your pores clean after wearing makeup all day!",
      gender: "female",
    },
  ];

  const ShampooReviewData = [
    {
      name: "Priyam C.",
      says: "I've been using this product for three years and consistently recommend it to my family. It's definitely worth the money. The Apple Cider Vinegar shampoo makes my hair shine and smooth.",
      gender: "male",
    },
    {
      name: "Imon S.",
      says: "Reviewing after the first use. This shampoo is mild with a light, long-lasting fragrance. Suitable for daily use, free from harsh chemicals. If applied on an oil-massaged scalp, a bit more shampoo is needed. Faced hair fall and dandruff issues; noticed a decrease after the first wash. Effectively cleans the scalp, visible difference in texture after the first use. Scalp feels cleaner, hair stronger.",
      gender: "male",
    },
    {
      name: "Rohit R.      ",
      says: "Since switching to this shampoo, I've noticed my dandruff and hair fall are under control. I've been using it for six months, and you can see changes within just two months. Highly recommend!",
      gender: "male",
    },

    {
      name: "Samyak R. ",
      says: "So far, this is the best purchase for my hair. Love the mild fragrance of fresh apples. After washing, my hair feels bouncy and lively. I use it thrice a week, and it keeps my scalp happy and moisturized. Note, I oil my hair before washing. No dandruff or hair fall issues before or after use. Extremely satisfied, won't go back to chemically made shampoos. Regarding the itching some mention, neither I nor my mother experienced that. Highly recommend!",
      gender: "male",
    },
    {
      name: "Gurram R. ",
      says: "I was searching for an ACV shampoo due to its clarifying and pH balancing benefits. With dry, frizzy, and curly hair, I tried this. Initially, no major change, but after 3 months, significant reduction in hair fall and improved texture. It also tackled my dandruff. True to its name, it makes my hair say 'WOW.' Now, my family and friends are hooked on this product.",
      gender: "male",
    },
    {
      name: "Shreyashi M. ",
      says: "Hey everyone,Sharing my experience ☺️. Love this natural product, no added chemicals, awesome fragrance, and affordable. Reduces dandruff. Wow Skin Science ACV is infused with sweet almond oil, argan oil, and 100% pure apple cider vinegar. Apply, lather, and rinse for a deep cleanse. Be wow, naturally 💚.",
      gender: "female",
    },
  ];

  const AloeVeraFaceWashReviews = [
    {
      name: "Aisha S.",
      says: "Ever since I started using this Aloe Vera face wash, my skin's dryness is history. It's like a perfect balancing act – no more dehydrated skin. Big shoutout to Wow for this game-changer!",
      gender: "female",
    },
    {
      name: "Arjun C.",
      says: "This Aloe Vera face wash is a game-changer! My face feels incredibly fresh and clean. I even got the 3-bottle offer for my sisters, and it's become a must-have in our skincare routine. Total win!",
      gender: "male",
    },
    {
      name: "Priya K.",
      says: "About to order my second batch of this incredible Aloe Vera face wash. The glow it leaves on my face is unmatched, and it feels so soft. The scent? Pure bliss. It's transformed my skin – fewer dark spots, less acne, and no more clogged pores. Thank you, Wow!",
      gender: "female",
    },
    {
      name: "Akshay S.",
      says: "For my sensitive skin, this product is great in summer or monsoon, but a bit drying in winter. I moisturize afterward to balance it. It doesn't foam much, but that doesn't affect its effectiveness. Lightly scented and easy to wash off. If you've got sensitive skin, add extra care with additional products. Washing and moisturizing are key.",
      gender: "male",
    },
    {
      name: "Rajat M.",
      says: "Decided to give this Aloe Vera face cleanser a shot, and it's a total win! My skin, which used to struggle with blemishes, now radiates with overall health. I won't settle for anything else – you'll absolutely fall for this product, just like I did.",
      gender: "male",
    },

    {
      name: "Meera P.   ",
      says: "This product is a true gem, especially the facial brush for that extra deep clean. Perfect for keeping pores clear after a long day of wearing makeup. A definite must-have in any skincare routine!",
      gender: "female",
    },
  ];

  const AntiAcneFaceSerum = [
    {
      name: "Priya C.",
      says: "Mind-boggling product with a mind-blowing performance! This serum is truly worth the value, delivering remarkable results that exceeded my expectations. My skin feels revitalized, and acne concerns have become a distant memory.",
      gender: "female",
    },
    {
      name: "Neha D.",
      says: "Effective and life-changing! This serum has worked wonders on my dry, menopause-affected skin. The glow is real, and my pigmentation is fading. Excited to see continued results!",
      gender: "female",
    },
    {
      name: "Aisha S.",
      says: "An absolute game-changer! The lightweight texture of this serum is a blessing for my dry skin, especially during winters. It hydrates seamlessly, leaving my skin smooth and radiant. Truly Wow!",
      gender: "female",
    },
    {
      name: "Vikram M.",
      says: "A true gem for sensitive skin! After years of battling acne scars, this serum is a savior. It's gentle yet powerful, reducing redness and blemishes. Highly recommend for those with similar concerns.",
      gender: "female",
    },
    {
      name: "Arjun P.",
      says: "A lifesaver! I battled stubborn acne for years, and this serum brought relief. After three months of use, my acne was under control. A fantastic product that delivers on its promise.",
      gender: "male",
    },

    {
      name: "Ananya T.",
      says: "Magical experience indeed! As a middle-aged woman, this serum addressed my age-related skin issues flawlessly. Fine lines vanished, and my skin is more vibrant. A must in my skincare regime!",
      gender: "female",
    },
  ];

  const VitaminCReviews = [
    {
      name: "Ritu A.      ",
      says: "WOW's Vitamin C face wash has been a lifesaver! My dull skin now radiates with newfound vitality. The active Vitamin C worked its magic, infusing brightness and energy. Niacinamide tackled my uneven skin tone, creating a canvas of clarity. This face wash isn't just a product; it's a daily ritual of transformation, leaving my skin revitalized and radiant. I've tried many, but this is the real game-changer in my skincare routine!",
      gender: "female",
    },
    {
      name: "Anjali S.",
      says: "Uneven skin tone bothered me, but WOW's Niacinamide-packed face wash addressed it remarkably. My complexion is now even and vibrant. Super impressed!",
      gender: "female",
    },
    {
      name: "Rahul D.",
      says: "Uneven skin tone was a constant battle until WOW's Niacinamide-packed face wash entered my life. The transformation has been remarkable—my once uneven complexion is now a harmonious blend of vibrancy. It's not just about skincare; it's about reclaiming confidence. This face wash has truly exceeded my expectations, and I can't recommend it enough to anyone struggling with similar concerns.",
      gender: "male",
    },
    {
      name: "Ayesha T.",
      says: "Dealing with stubborn dark spots, WOW's face wash was a revelation. The Liquorice & Mulberry extracts faded the spots, leaving my skin clear and spotless. Highly recommended!",
      gender: "female",
    },
    {
      name: "Karan K.",
      says: "As a man concerned about aging signs, WOW's Vitamin C face wash has become my secret weapon. The fine lines that once bothered me are noticeably reduced, thanks to the powerful blend of Vitamin C and botanical extracts. It's not just about a youthful appearance; it's about feeling confident and embracing the glow. My daily ritual now includes WOW, and I couldn't be happier with the results.",
      gender: "male",
    },

    {
      name: "Priya K.",
      says: "Pores and patchiness were persistent issues until I discovered WOW's face wash. The built-in brush became my skincare ally, clearing out my pores and giving my skin a renewed sense of freshness. Niacinamide worked its magic on my patchy complexion, creating a balanced and even skin tone. WOW has become my daily companion on the journey to clearer and healthier skin, and I couldn't be happier with the results.",
      gender: "female",
    },
  ];

  const RosemaryReviewData = [
    {
      name: "Radhika T.",
      says: "WOW's Rosemary & Biotin hair care set transformed my hair! My thin strands now feel stronger and fuller. The scent is divine, and my scalp is healthier. A game-changer for busy moms like me!",
      gender: "female",
    },
    {
      name: "Vikram S.",
      says: "Being in my 50s, finding a hair care routine that suits my changing hair was crucial. WOW's Rosemary & Biotin hair care set surpassed expectations. It adds thickness, reduces breakage, and the natural ingredients soothe my scalp. My hair feels revitalized, and the results are noticeable. A fantastic product for men looking to maintain healthy and strong hair.",
      gender: "male",
    },
    {
      name: "Kavita M.      ",
      says: "Being skeptical, I tried it reluctantly. Now, it's my go-to! Reduced hair fall, added shine, and softer texture. Love the natural ingredients. Highly recommend for fabulous hair.",
      gender: "female",
    },

    {
      name: "Rahul B. ",
      says: "As a guy always on the move, finding the right shampoo was a challenge. WOW's Rosemary & Biotin Shampoo and Hair Oil is a game-changer. Noticed a significant reduction in hair fall, and my hair feels stronger. The natural scent is refreshing, and it's become my daily go-to for a quick and effective haircare routine.",
      gender: "male",
    },
    {
      name: "Nandini A. ",
      says: "This rosemary and biotin hair care set revived my dull, lifeless hair. The fragrance is invigorating, and my hair feels incredibly soft. Noticed reduced breakage and increased thickness. A must-have in my routine!",
      gender: "female",
    },
    {
      name: "Pooja B. ",
      says: "WOW's Rosemary & Biotin hair pack exceeded my expectations. My aging hair looks vibrant, thicker, and healthier. The natural ingredients make it perfect for my sensitive scalp. Impressed and satisfied!",
      gender: "female",
    },
  ];

  const onionKit = [
    {
      name: "Nikita T",
      says: "This kit transformed my lifeless hair into a shining spectacle of health. I highly recommend it for the ultimate hair revival experience.",
      gender: "female",
    },
    {
      name: "Anusha B",
      says: "WOW's hair care kit worked wonders on my persistent hair fall. I'm genuinely thankful for this solution to my hair troubles.",
      gender: "female",
    },
    {
      name: "Nandini A",
      says: "WOW's kit delivered outstanding results!My hair is thicker, softer, and the shine is truly unbeatable. I wholeheartedly recommend it.",
      gender: "female",
    },

    {
      name: "Arjun M",
      says: "As a guy dealing with hair concerns, WOW's kit was a game-changer. It not only battled my dandruff but improved overall hair health.",
      gender: "male",
    },
    {
      name: "Rajesh B",
      says: "I'm blown away by how much WOW's kit reduced my dandruff, leaving my hair feeling incredible. It's a solid 5-star product for me.",
      gender: "male",
    },
    {
      name: "Meera K",
      says: "WOW's shampoo is my go-to! It effectively cleanses without drying, leaving my hair refreshed. Absolutely love the results – a must-try.",
      gender: "female",
    },
  ];

  const onionCompleteKit = [
    {
      name: "Preethi",
      says: "I was almost at my wits' end with hair fall until I found the WOW Hair Care Kit. The difference in my hair's health and strength is remarkable. It's a natural and effective solution that I highly recommend!",
      gender: "female",
    },
    {
      name: "Deepak",
      says: "The WOW Hair Care Kit has not only reduced my hair thinning but also rejuvenated my hair's appearance. The natural formulation is a big plus for me. My hair feels fuller and healthier now.",
      gender: "male",
    },
    {
      name: "Sindhu",
      says: "Initially skeptical, I'm now a believer in the WOW Hair Care Kit. My scalp and hair health have significantly improved. The Himalayan origin and natural ingredients make these products truly special",
      gender: "female",
    },

    {
      name: "Aishwarya",
      says: "Dealing with dry hair was frustrating, but WOW's Hair Care Kit has deeply moisturized and strengthened my hair. It's gentle, effective, and free from harmful chemicals. A must-have in my hair care regime.",
      gender: "female",
    },
    {
      name: "Biju",
      says: "In fashion, hair is key. The WOW Hair Care Kit leaves my hair silky, shiny, and smelling great. It's a fabulous product line that has greatly improved my hair's health and appearance.",
      gender: "male",
    },
    {
      name: "Jibin",
      says: "Working in hot kitchen conditions, my hair was suffering. The WOW Hair Care Kit has rejuvenated my hair health, making it stronger and healthier. It's remarkable the difference these products have made.",
      gender: "female",
    },

    
  ];

  return (
    <>
      {id === "apple-cider-vinegar-shampoo-set" && (
        <div className="container-2 bg-white pt-5" id="reviews">
          <div className="container-large-2">
            <h2 className="heading-2">Don’t just take our word for it</h2>
            <h5>See What Our Happy Customers Have to Say:</h5>
            <div className="testimonial-component">
              {ShampooReviewData?.map((review) => (
                <div className="testimonial-content">
                  <div className="rating-wrapper">
                    {[...Array(5)].map((_, index) => (
                      <RatingStar key={index} />
                    ))}
                  </div>
                  <div class="uui-heading-xxsmall">"{review.says}"</div>
                  <div className="testimonial-client">
                    <div>
                      <img
                        src={
                          review.gender === "female"
                            ? "/images/testimonial.png"
                            : "/images/testimonial-2.png"
                        }
                        className="customer-image"
                      />
                    </div>
                    <div className="ml-2">
                      <div class="client-heading">{review.name}</div>
                      <p className="mb-0">Verified Purchase🔒</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {id === "apple-cider-vinegar-shampoo-set-2" && (
        <div className="container-2 bg-white pt-5" id="reviews">
          <div className="container-large-2">
            <h2 className="heading-2">Don’t just take our word for it</h2>
            <h5>See What Our Happy Customers Have to Say:</h5>
            <div className="testimonial-component">
              {ShampooReviewData?.map((review) => (
                <div className="testimonial-content">
                  <div className="rating-wrapper">
                    {[...Array(5)].map((_, index) => (
                      <RatingStar key={index} />
                    ))}
                  </div>
                  <div class="uui-heading-xxsmall">"{review.says}"</div>
                  <div className="testimonial-client">
                    <div>
                      <img
                        src={
                          review.gender === "female"
                            ? "/images/testimonial.png"
                            : "/images/testimonial-2.png"
                        }
                        className="customer-image"
                      />
                    </div>
                    <div className="ml-2">
                      <div class="client-heading">{review.name}</div>
                      <p className="mb-0">Verified Purchase🔒</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {id === "apple-cider-vinegar-face-wash" && (
        <div className="container-2 bg-white pt-5" id="reviews">
          <div className="container-large-2">
            <h2 className="heading-2">Don’t just take our word for it</h2>
            <h5>See What Our Happy Customers Have to Say:</h5>
            <div className="testimonial-component">
              {ReviewData?.map((review) => (
                <div className="testimonial-content">
                  <div className="rating-wrapper">
                    {[...Array(5)].map((_, index) => (
                      <RatingStar key={index} />
                    ))}
                  </div>
                  <div class="uui-heading-xxsmall">"{review.says}"</div>
                  <div className="testimonial-client">
                    <div>
                      <img
                        src={
                          review.gender === "female"
                            ? "/images/testimonial.png"
                            : "/images/testimonial-2.png"
                        }
                        className="customer-image"
                      />
                    </div>
                    <div className="ml-2">
                      <div class="client-heading">{review.name}</div>
                      <p className="mb-0">Verified Purchase🔒</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {id === "apple-cider-vinegar-face-wash-set2" && (
        <div className="container-2 bg-white pt-5" id="reviews">
          <div className="container-large-2">
            <h2 className="heading-2">Don’t just take our word for it</h2>
            <h5>See What Our Happy Customers Have to Say:</h5>
            <div className="testimonial-component">
              {ReviewData?.map((review) => (
                <div className="testimonial-content">
                  <div className="rating-wrapper">
                    {[...Array(5)].map((_, index) => (
                      <RatingStar key={index} />
                    ))}
                  </div>
                  <div class="uui-heading-xxsmall">"{review.says}"</div>
                  <div className="testimonial-client">
                    <div>
                      <img
                        src={
                          review.gender === "female"
                            ? "/images/testimonial.png"
                            : "/images/testimonial-2.png"
                        }
                        className="customer-image"
                      />
                    </div>
                    <div className="ml-2">
                      <div class="client-heading">{review.name}</div>
                      <p className="mb-0">Verified Purchase🔒</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {id === "aloe-vera-face-wash" && (
        <div className="container-2 bg-white pt-5" id="reviews">
          <div className="container-large-2">
            <h2 className="heading-2">Don’t just take our word for it</h2>
            <h5>See What Our Happy Customers Have to Say:</h5>
            <div className="testimonial-component">
              {AloeVeraFaceWashReviews?.map((review) => (
                <div className="testimonial-content">
                  <div className="rating-wrapper">
                    {[...Array(5)].map((_, index) => (
                      <RatingStar key={index} />
                    ))}
                  </div>
                  <div class="uui-heading-xxsmall">"{review.says}"</div>
                  <div className="testimonial-client">
                    <div>
                      <img
                        src={
                          review.gender === "female"
                            ? "/images/testimonial.png"
                            : "/images/testimonial-2.png"
                        }
                        className="customer-image"
                      />
                    </div>
                    <div className="ml-2">
                      <div class="client-heading">{review.name}</div>
                      <p className="mb-0">Verified Purchase🔒</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {id === "anti-acne-face-serum" && (
        <div className="container-2 bg-white pt-5" id="reviews">
          <div className="container-large-2">
            <h2 className="heading-2">Don’t just take our word for it</h2>
            <h5>See What Our Happy Customers Have to Say:</h5>
            <div className="testimonial-component">
              {AntiAcneFaceSerum?.map((review) => (
                <div className="testimonial-content">
                  <div className="rating-wrapper">
                    {[...Array(5)].map((_, index) => (
                      <RatingStar key={index} />
                    ))}
                  </div>
                  <div class="uui-heading-xxsmall">"{review.says}"</div>
                  <div className="testimonial-client">
                    <div>
                      <img
                        src={
                          review.gender === "female"
                            ? "/images/testimonial.png"
                            : "/images/testimonial-2.png"
                        }
                        className="customer-image"
                      />
                    </div>
                    <div className="ml-2">
                      <div class="client-heading">{review.name}</div>
                      <p className="mb-0">Verified Purchase🔒</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {id === "vitamin-c-and-niacinamide-face-wash" && (
        <div className="container-2 bg-white pt-5" id="reviews">
          <div className="container-large-2">
            <h2 className="heading-2">Don’t just take our word for it</h2>
            <h5>See What Our Happy Customers Have to Say:</h5>
            <div className="testimonial-component">
              {VitaminCReviews?.map((review) => (
                <div className="testimonial-content">
                  <div className="rating-wrapper">
                    {[...Array(5)].map((_, index) => (
                      <RatingStar key={index} />
                    ))}
                  </div>
                  <div class="uui-heading-xxsmall">"{review.says}"</div>
                  <div className="testimonial-client">
                    <div>
                      <img
                        src={
                          review.gender === "female"
                            ? "/images/testimonial.png"
                            : "/images/testimonial-2.png"
                        }
                        className="customer-image"
                      />
                    </div>
                    <div className="ml-2">
                      <div class="client-heading">{review.name}</div>
                      <p className="mb-0">Verified Purchase🔒</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {id === "rosemary-oil-shampoo-and-hair-conditioner-combo" && (
        <div className="container-2 bg-white pt-5" id="reviews">
          <div className="container-large-2">
            <h2 className="heading-2">Don’t just take our word for it</h2>
            <h5>See What Our Happy Customers Have to Say:</h5>
            <div className="testimonial-component">
              {RosemaryReviewData?.map((review) => (
                <div className="testimonial-content">
                  <div className="rating-wrapper">
                    {[...Array(5)].map((_, index) => (
                      <RatingStar key={index} />
                    ))}
                  </div>
                  <div class="uui-heading-xxsmall">"{review.says}"</div>
                  <div className="testimonial-client">
                    <div>
                      <img
                        src={
                          review.gender === "female"
                            ? "/images/testimonial.png"
                            : "/images/testimonial-2.png"
                        }
                        className="customer-image"
                      />
                    </div>
                    <div className="ml-2">
                      <div class="client-heading">{review.name}</div>
                      <p className="mb-0">Verified Purchase🔒</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

{id === "onion-combo-kit" && (
        <div className="container-2 bg-white pt-5" id="reviews">
          <div className="container-large-2">
            <h2 className="heading-2">Don’t just take our word for it</h2>
            <h5>See What Our Happy Customers Have to Say:</h5>
            <div className="testimonial-component">
              {onionKit?.map((review) => (
                <div className="testimonial-content">
                  <div className="rating-wrapper">
                    {[...Array(5)].map((_, index) => (
                      <RatingStar key={index} />
                    ))}
                  </div>
                  <div class="uui-heading-xxsmall">"{review.says}"</div>
                  <div className="testimonial-client">
                    <div>
                      <img
                        src={
                          review.gender === "female"
                            ? "/images/testimonial.png"
                            : "/images/testimonial-2.png"
                        }
                        className="customer-image"
                      />
                    </div>
                    <div className="ml-2">
                      <div class="client-heading">{review.name}</div>
                      <p className="mb-0">Verified Purchase🔒</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

{id === "onion-complete-hair-care-kit" && (
        <div className="container-2 bg-white pt-5" id="reviews">
          <div className="container-large-2">
            <h2 className="heading-2">Don’t just take our word for it</h2>
            <h5>See What Our Happy Customers Have to Say:</h5>
            <div className="testimonial-component">
              {onionCompleteKit?.map((review) => (
                <div className="testimonial-content">
                  <div className="rating-wrapper">
                    {[...Array(5)].map((_, index) => (
                      <RatingStar key={index} />
                    ))}
                  </div>
                  <div class="uui-heading-xxsmall">"{review.says}"</div>
                  <div className="testimonial-client">
                    <div>
                      <img
                        src={
                          review.gender === "female"
                            ? "/images/testimonial.png"
                            : "/images/testimonial-2.png"
                        }
                        className="customer-image"
                      />
                    </div>
                    <div className="ml-2">
                      <div class="client-heading">{review.name}</div>
                      <p className="mb-0">Verified Purchase🔒</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default CustomerReview;
